import { LoadingSpinner } from 'authflow-ui-engine'
import { Redirect, Route, Switch, useLocation } from 'wouter'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import styled, { useTheme } from 'styled-components'

import { NotFoundPage } from '../pages/NotFoundPage'
import { ContentWrap, RouterContainer } from '../styles/generic.styles'
import { StoreContext } from './App'
import { Menu } from './Menu/Menu'
import { ClientsTablePage } from '../pages/ClientsTable/ClientsTablePage'
import { EntitiesTablePage } from '../pages/EntitiesTable/EntitiesTablePage'
import { SingleClientPage } from '../pages/SingleClient/SingleClientPage'
import { SingleEntityPage } from '../pages/SingleEntity/SingleEntityPage'
import { LoginPage } from '../pages/Login/LoginPage'
import { UsersTablePage } from '../pages/UsersTable/UsersTablePage'
import { STAGE } from '../constants/envs'
import { DebugPage } from '../pages/Debug/DebugPage'
import { GroupsTablePage } from '../pages/GroupsTable/GroupsTablePage'
import { PermissionsTablePage } from '../pages/PermissionsTable/PermissionsTablePage'

const LoadWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const URLS_WITHOUT_AUTH = ['/login', '/register', '/error']
export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { initialAuthCheck, isAuthenticated } = store.CommunicationState

  const theme = useTheme()

  const [location] = useLocation()

  console.log('theme', theme)

  // eslint-disable-next-line react/prop-types
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        component={(props) => {
          if (!initialAuthCheck) {
            return (
              <LoadWrap>
                <LoadingSpinner {...theme.loadingSpinner} width="48px" padding="0 0" thickness={4} />
              </LoadWrap>
            )
          } else if (isAuthenticated) {
            return <Component {...props} />
          } else {
            return <Redirect to="/login" />
          }
        }}
      />
    )
  }

  const menuVisible = !URLS_WITHOUT_AUTH.includes(location)

  return (
    <RouterContainer>
      {menuVisible && <Menu />}

      <ContentWrap $menuVisible={menuVisible}>
        <Switch>
          <Route path="/login" component={LoginPage} />

          <PrivateRoute path="/" component={ClientsTablePage} />
          <PrivateRoute path="/clients" component={ClientsTablePage} />
          <PrivateRoute path="/clients/:id" component={SingleClientPage} />

          <PrivateRoute path="/entities" component={EntitiesTablePage} />
          <PrivateRoute path="/entities/:id" component={SingleEntityPage} />

          <PrivateRoute path="/users" component={UsersTablePage} />
          <PrivateRoute path="/groups" component={GroupsTablePage} />
          <PrivateRoute path="/permissions" component={PermissionsTablePage} />

          {/* Local dev debug page */}
          {STAGE === 'local' && <Route path="/debug" component={DebugPage} />}

          {/* Default route in a switch */}
          <Route>
            <LoadWrap>
              <NotFoundPage />
            </LoadWrap>
          </Route>
        </Switch>
      </ContentWrap>
    </RouterContainer>
  )
})
