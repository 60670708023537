import { createGlobalStyle } from 'styled-components'
import MabryProRegular from '../assets/mabry-regular-pro.woff2'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Mabry Pro';
    src: url('${MabryProRegular}') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-weight: inherit;
  }

  ol, ul {
    list-style: none;
  }
  
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  
  a {
    color: inherit;
    cursor: pointer;
    
    &:hover {
      cursor: pointer;
    }
  }
  
  html {
    width: 100%;
    height: 100%;
    font-family: ${({ theme }) => theme.fonts.family};
    font-size: ${({ theme }) => theme.fonts.size}px;
    font-weight: normal;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.primary};

    body {
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.quaternary};

      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background-color: ${({ theme }) => (theme.colors.scrollTrackColor ? theme.colors.scrollTrackColor : '#ebebeb')};
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: ${({ theme }) => (theme.colors.scrollHandleColor ? theme.colors.scrollHandleColor : '#6d6d6d')};
      }

      /* using div instead of div#app makes modal portal visible all the time making website y axis overflow */
      > div#app {
        width: 100%;
        /* height: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
          height: auto;
          display: block;
        }
      }
    }
  }
`
