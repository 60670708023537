import { FC, PropsWithChildren, useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'wouter'
import { Menu as DropdownMenu, MenuItem as DropdownMenuItem } from '@szhsin/react-menu'
import { axiosInstance } from '../../methods/axiosConfig'
import { StoreContext } from '../App'
import useCheckMobile from '../../hooks/useCheckMobile'
import {
  StyledMenu,
  MenuNav,
  MenuToolbox,
  Overlay,
  PositionedHamburger,
  MenuDropdownButton,
  MenuDropdownWrapper,
  MenuToolboxMobile,
} from './Menu.styles'
import { LogoutIcon } from '../Icons/LogoutIcon'
import { LogsIcon } from '../Icons/LogsIcon'
import { StyledLogo } from './Menu.styles'
import { MenuItem } from './MenuItem'
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher'
import { MenuItemIcon } from './MenuItemIcon'
import { MenuItemName } from './MenuItemName'
import { Logo } from '../Logo/Logo'
import { useLocalStorage } from 'usehooks-ts'
import { ThemeIcon } from '../ThemeSwitcher/ThemeIcon'
import { HamburgerIcon } from '../Icons/HamburgerIcon'
import { CloseIcon } from '../Icons/CloseIcon'
import { useTheme } from 'styled-components'
import { ThreeDotsIcon } from '../Icons/ThreeDotsIcon'
import { UsersIcon } from '../Icons/UsersIcon'
import { STAGE } from '../../constants/envs'
import { SettingsIcon } from '../Icons/SettingsIcon'
import { PermsIcon } from '../Icons/PermsIcon'

export const Menu: FC<PropsWithChildren> = observer(() => {
  const theme = useTheme()
  const isMobileView = useCheckMobile()
  const store = useContext(StoreContext)
  const { openedPopupCount } = store.AppState
  const { currentUser } = store.CommunicationState

  const [location, setLocation] = useLocation()
  const [menuOpened, setMenuOpened] = useLocalStorage('menuOpened', true)

  const toggleTheme = () => {
    store.AppState.toggleTheme()
  }

  const logout = useCallback(async () => {
    await axiosInstance.get(`logout`)

    store.CommunicationState.setAuthorization(false)

    if (isMobileView) setMenuOpened(false)

    setLocation('/login')
  }, [])

  const isAllowedToManageConfig = currentUser?.capabilities?.includes('CLIENT_CONFIG_MANAGEMENT')

  const isAllowedToManageAccess = currentUser?.capabilities?.includes('ACCESS_MANAGEMENT')

  return (
    <>
      <PositionedHamburger $visible={!openedPopupCount}>
        {!menuOpened ? (
          <HamburgerIcon onClick={() => setMenuOpened(!menuOpened)}></HamburgerIcon>
        ) : (
          <CloseIcon onClick={() => setMenuOpened(!menuOpened)}></CloseIcon>
        )}
      </PositionedHamburger>

      <StyledMenu open={menuOpened}>
        <MenuNav>
          <StyledLogo>
            <Logo />
          </StyledLogo>

          {isAllowedToManageConfig && (
            <>
              <MenuItem path={['/', '/clients', '/clients/*']}>
                <MenuItemIcon>
                  <LogsIcon />
                </MenuItemIcon>
                <MenuItemName>Clients</MenuItemName>
              </MenuItem>
              <MenuItem path={['/entities', '/entities/*']}>
                <MenuItemIcon>
                  <LogsIcon />
                </MenuItemIcon>
                <MenuItemName>Entities</MenuItemName>
              </MenuItem>
            </>
          )}

          {isAllowedToManageAccess && (
            <>
              <MenuItem path="/users">
                <MenuItemIcon>
                  <UsersIcon />
                </MenuItemIcon>
                <MenuItemName>Users</MenuItemName>
              </MenuItem>
              <MenuItem path="/groups">
                <MenuItemIcon>
                  <UsersIcon />
                </MenuItemIcon>
                <MenuItemName>Groups</MenuItemName>
              </MenuItem>
              <MenuItem path="/permissions">
                <MenuItemIcon>
                  <PermsIcon />
                </MenuItemIcon>
                <MenuItemName>Permissions</MenuItemName>
              </MenuItem>
            </>
          )}

          {STAGE === 'local' && (
            <MenuItem path="/debug">
              <MenuItemIcon>
                <SettingsIcon />
              </MenuItemIcon>
              <MenuItemName>Debug</MenuItemName>
            </MenuItem>
          )}
        </MenuNav>

        <MenuToolbox>
          <MenuDropdownWrapper>
            <DropdownMenu
              menuButton={
                <MenuDropdownButton>
                  <ThreeDotsIcon />
                </MenuDropdownButton>
              }
            >
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation = true
                  e.keepOpen = true
                }}
              >
                <ThemeSwitcher onClick={toggleTheme}>
                  <ThemeIcon />
                  <MenuItemName>{theme.name}</MenuItemName>
                </ThemeSwitcher>
              </DropdownMenuItem>

              <DropdownMenuItem onClick={logout}>
                <MenuItemIcon>
                  <LogoutIcon />
                </MenuItemIcon>
                <MenuItemName>{'Logout'}</MenuItemName>
              </DropdownMenuItem>
            </DropdownMenu>
          </MenuDropdownWrapper>
        </MenuToolbox>

        <MenuToolboxMobile>
          <MenuItem>
            <ThemeSwitcher onClick={toggleTheme}>
              <ThemeIcon />
              <MenuItemName>{theme.name}</MenuItemName>
            </ThemeSwitcher>
          </MenuItem>

          <MenuItem onClick={logout}>
            <MenuItemIcon>
              <LogoutIcon />
            </MenuItemIcon>
            <MenuItemName>Logout</MenuItemName>
          </MenuItem>
        </MenuToolboxMobile>
      </StyledMenu>
      <Overlay
        $visible={menuOpened}
        onClick={() => {
          setMenuOpened(false)
        }}
      />
    </>
  )
})
