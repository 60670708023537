import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button } from 'authflow-ui-engine'
import {
  CenteredText,
  ListGrid,
  ListGridItem,
  LoaderWrapper,
  ManageGroupModalContainer,
  PopupSubtitle,
  PopupTitle,
} from '../PermissionsTablePage.styles'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { StoreContext } from '../../../components/App'
import { AddGroupToPermissionModal } from './AddGroupToPermissionModal'
import { listPermissionsForResource } from '../../../communication/Permissions'
import { Permission } from '../../../types/Permissions'
import { Popup } from '../../../components/Popup'

interface ManagePermissionModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const ManagePermissionModal: FC<ManagePermissionModalProps> = observer(({ isOpen, setIsOpen }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { isPermActionLoading, currentPermission } = store.CommunicationState

  const [isLoading, setIsLoading] = useState(false)
  const [fetchedPerm, setFetchedPerm] = useState<Permission>({} as Permission)

  const [isAddGroupToPermModal, setIsAddGroupToPermModal] = useState(false)

  const fetchPerm = async () => {
    if (!currentPermission?.resourceType) return

    setIsLoading(true)

    const { data } = await listPermissionsForResource({
      resourceType: currentPermission?.resourceType,
      resourceName: currentPermission?.resourceName,
    })

    setFetchedPerm(
      data?.permissions?.filter((perm) => {
        const resourceType = perm?.resourceType === currentPermission?.resourceType
        const resourceName = perm?.resourceName === currentPermission?.resourceName
        const operation = perm?.operation === currentPermission?.operation

        return resourceType && resourceName && operation
      })[0],
    )

    setIsLoading(false)
  }

  useEffect(() => {
    if (isPermActionLoading) return
    fetchPerm()
  }, [isPermActionLoading, currentPermission])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleGroupRemoval = async (groupId: string) => {}

  return (
    <Popup
      width="800px"
      visibility={isOpen}
      handleVisibility={setIsOpen}
      {...theme.popup}
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      {isPermActionLoading || isLoading ? (
        <LoaderWrapper>
          <LoadingSpinner width="60px" {...theme.loadingSpinner} />
        </LoaderWrapper>
      ) : (
        <ManageGroupModalContainer>
          <PopupTitle>Permission</PopupTitle>

          <PopupSubtitle $textAlign="center">
            {fetchedPerm?.resourceType} - {fetchedPerm?.resourceName} - {fetchedPerm?.operation}
          </PopupSubtitle>

          <PopupSubtitle>
            Groups:
            <Button
              {...theme.buttons.commonButton}
              width="120px"
              widthMobile="120px"
              height="40px"
              onClick={() => {
                setIsAddGroupToPermModal(true)
              }}
            >
              Add groups
            </Button>
          </PopupSubtitle>

          {fetchedPerm?.allowedGroups?.length ? (
            <ListGrid $columnsTemplate="auto auto auto">
              <ListGridItem $header>id</ListGridItem>
              <ListGridItem $header>name</ListGridItem>
              <ListGridItem $header>action</ListGridItem>

              {fetchedPerm?.allowedGroups?.map((group) => (
                <>
                  <ListGridItem>{group?.id}</ListGridItem>
                  <ListGridItem>{group?.name || 'N/A'}</ListGridItem>
                  <ListGridItem>
                    <Button
                      {...theme.buttons.commonButton}
                      width="90px"
                      height="36px"
                      onClick={() => {
                        handleGroupRemoval(group?.id)
                      }}
                    >
                      [WiP]
                    </Button>
                  </ListGridItem>
                </>
              ))}
            </ListGrid>
          ) : (
            <CenteredText> No groups added </CenteredText>
          )}
        </ManageGroupModalContainer>
      )}

      <AddGroupToPermissionModal
        isOpen={isAddGroupToPermModal}
        setIsOpen={setIsAddGroupToPermModal}
        permission={currentPermission}
      />
    </Popup>
  )
})
