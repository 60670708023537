import styled from 'styled-components'

export const StyledMenuItemName = styled.div`
  flex: 1;
  font-size: 0.94rem;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: 1.22;
  margin-top: -2px;
`
