import { FC, MouseEventHandler, PropsWithChildren, useCallback } from 'react'
import { Link, StyledMenuItem } from './MenuItem.styles'
import { useLocation } from 'wouter'
import useCheckMobile from '../../hooks/useCheckMobile'
import { useLocalStorage } from 'usehooks-ts'

type Props = {
  children: React.ReactNode
  link?: string
  path?: string | string[]
  onClick?: MouseEventHandler
}

export const MenuItem: FC<PropsWithChildren<Props>> = ({ children, link, path, onClick }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [menuOpened, setMenuOpened] = useLocalStorage('menuOpened', true)

  const isMobileView = useCheckMobile()
  const [location, setLocation] = useLocation()

  const redirect = useCallback(() => {
    const resolvedPath = resolvePath()
    if (isMobileView) setMenuOpened(false)

    setLocation(resolvedPath)
  }, [])

  const isActive = () => {
    if (Array.isArray(path)) {
      // subpath handling
      const urlWithAsterisk = path.find((item) => item.includes('*'))
      const urlBeforeAsterisk = urlWithAsterisk.substring(0, urlWithAsterisk.indexOf('*') - 1)
      if (urlWithAsterisk && location.includes(urlBeforeAsterisk)) {
        return true
      }

      return path.includes(location)
    } else {
      return path === location
    }
  }

  const resolvePath = () => {
    if (Array.isArray(path)) {
      return path[0]
    }

    return path
  }

  return (
    <StyledMenuItem $active={path && isActive()} onClick={path ? redirect : onClick}>
      <Link href={link}>{children}</Link>
    </StyledMenuItem>
  )
}
