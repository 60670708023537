import styled, { css } from 'styled-components'
import SearchIconLightTheme from '../components/Icons/SearchIconLightTheme.svg'
import SearchIconDarkTheme from '../components/Icons/SearchIconDarkTheme.svg'

export const Animatable = css`
  ${({ theme }) =>
    theme.animations.enabled &&
    css`
      transition: all ${({ theme }) => theme.animations.duration} ${({ theme }) => theme.animations.function};
    `}
`

export const Spacer = styled.div<{ margin?: string; size?: number; width?: string; $desktopOnly?: boolean }>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ margin }) => css`
    margin: ${margin || '0'};
  `}

  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
      min-height: ${size}px;
    `}

  ${({ $desktopOnly }) =>
    $desktopOnly &&
    css`
      @media (max-width: 980px) {
        display: none;
      }
    `}
`

export const RouterContainer = styled.div.attrs({
  className: 'content-router',
})`
  display: flex;
  flex-direction: column;
  height: 1px;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
`

export const ContentWrap = styled.div<{ $menuVisible?: boolean }>`
  min-height: ${({ $menuVisible }) => ($menuVisible ? 'calc(100vh - 55px);' : '100vh')};
  width: 100%;

  @media (max-width: 980px) {
    width: 100%;
    min-width: auto;
    margin: 0;
    padding: 0;

    > div {
      height: calc(100vh - 120px);
      position: relative;
      border-bottom: none;
      border: none;
      box-shadow: none;
    }
  }

  button {
    transition: all 0.2s ease-in-out;
  }

  a {
    font-weight: 700;
  }
`

export const InnerAppLayout = styled.div`
  display: flex;
  height: calc(100% - 62px);
`

interface InnerAppContentProps {
  padding?: string
  mobilePadding?: string
}

export const InnerAppContent = styled.div<InnerAppContentProps>`
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '10px 40px 40px')};
  background-color: transparent;

  @media (max-width: 980px) {
    padding: ${(props) => (props.mobilePadding ? props.mobilePadding : '10px 20px 20px')};
  }
`

export const DevTools = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid gray;
  box-sizing: border-box;
  padding: 20px;
`

interface MarginerProps {
  margin?: string
}

export const Marginer = styled.div<MarginerProps>`
  margin: ${(props) => (props.margin ? props.margin : '0')};
`

export const SearchInput = styled.input<{
  $isDarkTheme?: boolean
}>`
  width: 100%;
  min-width: 114px;
  height: 40px;
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  padding: 6px 6px 6px 35px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.quinary};
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: inherit;
  background-image: url(${(props) => (props.$isDarkTheme ? SearchIconDarkTheme : SearchIconLightTheme)});
  background-position: 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary2};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.quinary};
    background-color: ${({ theme }) => theme.colors.quinary};
    color: ${({ theme }) => theme.colors.septenary2};
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.quinary};
    }
  }

  ${Animatable}
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 30px;
  line-height: 1.22;
`

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 26px;
  line-height: 1.22;
`

export const SelectInputWrapper = styled.div`
  width: 100%;
  min-height: 40px;

  & > *,
  & > * > * {
    min-height: 40px;
  }

  .authflow-select-input {
    .select-input__control {
      min-height: 40px;

      & > .select-input__value-container {
        padding: 0px 16px;
      }
    }
  }
`

export const TextInputWrapper = styled.div<{ height?: string }>`
  width: 100%;

  & > div {
    & > input {
      height: ${({ height }) => height || '40px'};
    }
  }
`
