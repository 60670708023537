import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button } from 'authflow-ui-engine'
import {
  PopupTitle,
  LoaderWrapper,
  ManageGroupModalContainer,
  PopupSubtitle,
  ListGrid,
  ListGridItem,
  CenteredText,
} from '../GroupsTablePage.styles'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { AddUsersToGroupModal } from './AddUsersToGroupModal'
import { deleteUsersFromGroup, getGroup } from '../../../communication/Groups'
import { Group } from '../../../types/Group'
import { deletePermission, listPermissionsForGroup } from '../../../communication/Permissions'
import { Permission } from '../../../types/Permissions'
import { AddPermissionToGroupModal } from './AddPermissionToGroup'
import { StoreContext } from '../../../components/App'
import { Popup } from '../../../components/Popup'

interface ManageGroupModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  groupId: string
}

export const ManageGroupModal: FC<ManageGroupModalProps> = observer(({ isOpen, setIsOpen, groupId }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { isGroupActionLoading } = store.CommunicationState

  const [currentGroup, setCurrentGroup] = useState<Group>({} as Group)
  const [isLoading, setIsLoading] = useState(false)

  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState(false)

  const [isAddPermissionModalOpen, setIsAddPermissionModalOpen] = useState(false)
  const [groupPermissions, setGroupPermissions] = useState<Permission[]>([])

  const fetchGroup = async () => {
    setIsLoading(true)

    const { data } = await getGroup(groupId)
    setCurrentGroup(data)
    console.log(data)

    setIsLoading(false)
  }

  const fetchGroupPermissions = async () => {
    setIsLoading(true)

    const { data } = await listPermissionsForGroup(groupId)
    setGroupPermissions(data.permissions)
    console.log(data)

    setIsLoading(false)
  }

  useEffect(() => {
    if (isOpen && !isGroupActionLoading) {
      void fetchGroup()
      void fetchGroupPermissions()
    }
  }, [isOpen, isGroupActionLoading])

  const handleUserRemoval = async (userId: string) => {
    await deleteUsersFromGroup({ groupId: currentGroup?.id, memberIds: [userId] })

    await fetchGroup()
  }

  const handlePermissionRemoval = async (permission: Permission) => {
    setIsLoading(true)

    await deletePermission(permission)
    await fetchGroupPermissions()

    setIsLoading(false)
  }

  return (
    <Popup
      width="800px"
      visibility={isOpen}
      handleVisibility={setIsOpen}
      {...theme.popup}
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      {isLoading ? (
        <LoaderWrapper>
          <LoadingSpinner width="60px" {...theme.loadingSpinner} />
        </LoaderWrapper>
      ) : (
        <ManageGroupModalContainer>
          <PopupTitle>Group - {currentGroup?.name}</PopupTitle>

          <PopupSubtitle>
            Permissions:
            <Button
              {...theme.buttons.commonButton}
              width="120px"
              widthMobile="120px"
              height="40px"
              onClick={() => {
                setIsAddPermissionModalOpen(true)
              }}
            >
              Add Permission
            </Button>
          </PopupSubtitle>

          {groupPermissions?.length ? (
            <ListGrid $columnsTemplate="auto auto auto auto">
              <ListGridItem $header>resourceType</ListGridItem>
              <ListGridItem $header>resourceName</ListGridItem>
              <ListGridItem $header>operation</ListGridItem>
              <ListGridItem $header>Action</ListGridItem>

              {groupPermissions?.map((perm) => (
                <>
                  <ListGridItem>{perm?.resourceType}</ListGridItem>
                  <ListGridItem>{perm?.resourceName}</ListGridItem>
                  <ListGridItem>{perm?.operation}</ListGridItem>
                  <ListGridItem>
                    <Button
                      {...theme.buttons.commonButton}
                      width="90px"
                      height="36px"
                      onClick={() => {
                        handlePermissionRemoval(perm)
                      }}
                    >
                      Delete
                    </Button>
                  </ListGridItem>
                </>
              ))}
            </ListGrid>
          ) : (
            <CenteredText> No permissions </CenteredText>
          )}

          <PopupSubtitle>
            Members:
            <Button
              {...theme.buttons.commonButton}
              width="120px"
              widthMobile="120px"
              height="40px"
              onClick={() => {
                setIsAddUsersModalOpen(true)
              }}
            >
              Add Users
            </Button>
          </PopupSubtitle>

          {currentGroup?.members?.length ? (
            <ListGrid $columnsTemplate="auto auto 100px auto">
              <ListGridItem $header>Name</ListGridItem>
              <ListGridItem $header>User ID</ListGridItem>
              <ListGridItem $header>isDeleted</ListGridItem>
              <ListGridItem $header>Action</ListGridItem>

              {currentGroup?.members?.map((member) => (
                <>
                  <ListGridItem>{member?.name || 'N/A'}</ListGridItem>
                  <ListGridItem>{member?.userId}</ListGridItem>
                  <ListGridItem>{`${member?.isDeleted || false}`}</ListGridItem>
                  <ListGridItem>
                    <Button
                      {...theme.buttons.commonButton}
                      width="90px"
                      height="36px"
                      onClick={() => {
                        handleUserRemoval(member?.userId)
                      }}
                    >
                      Delete
                    </Button>
                  </ListGridItem>
                </>
              ))}
            </ListGrid>
          ) : (
            <CenteredText> No users </CenteredText>
          )}
        </ManageGroupModalContainer>
      )}

      <AddUsersToGroupModal isOpen={isAddUsersModalOpen} setIsOpen={setIsAddUsersModalOpen} group={currentGroup} />

      <AddPermissionToGroupModal
        isOpen={isAddPermissionModalOpen}
        setIsOpen={setIsAddPermissionModalOpen}
        group={currentGroup}
      />
    </Popup>
  )
})
