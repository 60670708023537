import { FC, useCallback, useState } from 'react'
import {
  Container,
  LeftSide,
  RightSide,
  Aligner,
  PositionedLogo,
  TransparentContainer,
  PositionedIllustration,
} from './LoginPage.styles'
import { Button, LoadingSpinner } from 'authflow-ui-engine'
import { Spacer, Subtitle, Title } from '../../styles/generic.styles'
import { LoginIllustration } from './components/LoginIllustration'
import { useTheme } from 'styled-components'
import { API_URL } from '../../constants/envs'

export const LoginPage: FC = () => {
  const theme = useTheme()

  const [redirecting, setRedirecting] = useState(false)

  const handleAuth = useCallback(() => {
    setRedirecting(true)

    window.location.href = `${API_URL}/redirect-to-auth`
  }, [])

  return (
    <Container>
      <LeftSide>
        <PositionedLogo>{/* <Logo></Logo> */}</PositionedLogo>
        <PositionedIllustration>
          <LoginIllustration></LoginIllustration>
        </PositionedIllustration>
      </LeftSide>
      <RightSide>
        <Aligner>
          {!redirecting ? (
            <TransparentContainer>
              <Title>Welcome back!</Title>
              <Spacer size={14}></Spacer>
              <Subtitle>Please login using your Signicat’s Microsoft account</Subtitle>
              <Spacer size={50}></Spacer>
              <Button {...theme.buttons.commonButton} onClick={handleAuth} width="250px" margin="0 auto">
                Log in
              </Button>
            </TransparentContainer>
          ) : (
            <TransparentContainer>
              <Title>Redirecting...</Title>
              <Spacer size={14}></Spacer>
              <Subtitle>Please wait, we are redirecting you to Microsoft</Subtitle>
              <Spacer size={50}></Spacer>
              <LoadingSpinner {...theme.loadingSpinner} width="60px" />
            </TransparentContainer>
          )}
        </Aligner>
      </RightSide>
    </Container>
  )
}
