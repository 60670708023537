import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../components/App'

export const NotFoundPage = observer(() => {
  const store = useContext(StoreContext)
  const { NotFound: trans } = store.TranslationsState.translations

  return <div className="not-found">{trans.title}</div>
})
