import styled, { css } from 'styled-components'
import { Animatable } from '../../styles/generic.styles'

export const StyledMenuItem = styled.div<{
  $active?: boolean
}>`
  width: 170px;
  height: 36px;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.senary};
  flex: 1;
  border-bottom: 2px solid transparent;

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.secondary9};
      border-bottom: 2px solid ${({ theme }) => theme.colors.secondary9};
    `}

  ${({ $active }) =>
    !$active &&
    css`
      &:hover {
        color: ${({ theme }) => theme.colors.secondary2};
        border-bottom: 2px solid ${({ theme }) => theme.colors.secondary2};
      }
    `}

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: calc(100% - 2 * 15px);
    flex: 0 0 48px;
    height: 48px;
    border-radius: 26px;
    border-bottom: none;

    ${({ $active }) =>
      $active &&
      css`
        color: ${({ theme }) => theme.colors.quaternary};
        background-color: ${({ theme }) => theme.colors.secondary9};
      `}

    ${({ $active }) =>
      !$active &&
      css`
        &:hover {
          color: ${({ theme }) => theme.colors.quaternary};
          border-bottom: none;
        }
      `}
  }

  ${Animatable}
`

export const Link = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: inherit;
  text-decoration: inherit;
`
