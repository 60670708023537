import { FC, useContext, useEffect, useRef, useState } from 'react'
import { LoadingSpinner, Button } from 'authflow-ui-engine'
import { Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { StoreContext } from '../../components/App'
import { CloseIcon } from '../../components/Icons/CloseIcon'
import { JSONEditor } from '../../components/JSONEditor/JSONEditor'
import {
  FlexCol,
  FlexRow,
  JSONViewerWrapper,
  LoaderWrapper,
  Page,
  PageContainer,
  PageTitle,
  PageTopContainer,
  PropertyPreviewPopupContainer,
  PropertyPreviewPopupValue,
  SectionTitle,
  TagsContainer,
  TagsItem,
  TagsList,
  VersionItem,
  VersionList,
} from '../../styles/objectViewPages.styles'
import { useTimeout } from 'usehooks-ts'
import { getClient } from '../../communication/Configurations'
import { Client } from '../../types/Client'
import { useLocation } from 'wouter'
import { BackArrowIcon } from '../../components/Icons/BackArrow'
import { Popup } from '../../components/Popup'
import { navigate } from 'wouter/use-browser-location'

interface SingleClientPageProps {
  params: {
    id: string
  }
}

export const SingleClientPage: FC<SingleClientPageProps> = observer(({ params }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  //eslint-disable-next-line
  const [location, setLocation] = useLocation()

  const [fetchedClient, setFetchedClient] = useState<Client>({} as Client)

  const [fetching, setFetching] = useState(true)

  const fetchClient = async () => {
    setFetching(true)

    const { data } = await getClient(params.id)
    setFetchedClient(data)
    store.CommunicationState.setClient(data)

    setFetching(false)
  }

  useEffect(() => {
    fetchClient()
  }, [])

  const [isObjectPreviewOpen, setIsObjectPreviewOpen] = useState(false)
  const [propertyPreviewName, setPropertyPreviewName] = useState('')

  const [isVersionPreviewOpen, setIsVersionPreviewOpen] = useState(false)
  const [versionPreviewNumber, setVersionPreviewNumber] = useState<number>()

  const renderPreviewModalBody = () => {
    try {
      // const previewValue = getResolvedValueByUnresolvedPath(propertyPreviewName, fetchedClient?.referenceMapping)
      const previewValue = fetchedClient?.referenceMapping.find(
        (item) => item.unresolvedPath === propertyPreviewName,
      )?.resolvedValue

      if (typeof previewValue === 'object' && !Array.isArray(previewValue) && previewValue !== null) {
        return (
          <>
            {previewValue?.clientId ? (
              <SectionTitle
                $clickable
                onClick={() => {
                  navigate(`/${previewValue?.type === 'Entity' ? 'entities' : 'clients'}/${previewValue?.clientId}`)
                }}
              >
                {previewValue?.clientId}
              </SectionTitle>
            ) : (
              <SectionTitle>{propertyPreviewName}</SectionTitle>
            )}

            <JSONEditor value={JSON.stringify(previewValue, undefined, 4)} />
          </>
        )
      }

      return (
        <>
          <SectionTitle>{propertyPreviewName}</SectionTitle>
          <PropertyPreviewPopupValue>{previewValue.toString()}</PropertyPreviewPopupValue>
        </>
      )
    } catch (error) {
      return <>something went wrong</>
    }
  }

  const JSONPropIconOnclick = (propertyName: string) => {
    setPropertyPreviewName('current/' + propertyName)
    setIsObjectPreviewOpen(true)
  }

  const currentContainer = useRef<HTMLDivElement>(null)
  const [JSONWrapperHeight, setJSONWrapperHeight] = useState('50vh' as string)

  useTimeout(() => {
    if (currentContainer.current) {
      setJSONWrapperHeight(`${currentContainer?.current?.clientHeight}px`)
    }
  }, 200)

  const [pageKey, setPageKey] = useState(0)

  useEffect(() => {
    setPageKey(pageKey + 1)
  }, [fetchedClient])

  return (
    <Page key={`client-${pageKey}`}>
      <PageTopContainer>
        <PageTitle>
          <Button
            {...theme.buttons.commonButton}
            height="36px"
            width="36px"
            heightMobile="36px"
            widthMobile="36px"
            padding="0"
            paddingMobile="0"
            onClick={() => {
              setLocation('/clients')
            }}
          >
            <BackArrowIcon size="36px" />
          </Button>
          {params.id}
        </PageTitle>
      </PageTopContainer>

      <Spacer size={20} />

      <PageContainer>
        {!fetching && fetchedClient?.current?.clientId ? (
          <FlexCol>
            {!!fetchedClient?.references?.length && (
              <>
                <FlexRow $height="unset">
                  <TagsContainer>
                    <SectionTitle>References:</SectionTitle>
                    <TagsList>
                      {fetchedClient?.references.map((reference, index) => (
                        <TagsItem key={index}>{reference}</TagsItem>
                      ))}
                    </TagsList>
                  </TagsContainer>
                </FlexRow>
                <Spacer size={16} />
              </>
            )}

            <FlexRow $height="100%">
              <FlexCol $maxHeight="100%" $width="70%" ref={currentContainer}>
                <JSONViewerWrapper $maxHeight={JSONWrapperHeight}>
                  <JSONEditor
                    value={fetchedClient?.current}
                    maxHeight="100%"
                    widgetOnClick={JSONPropIconOnclick}
                    widgetPropNameList={fetchedClient?.referenceMapping?.map((item) => item.unresolvedPath)}
                  />
                </JSONViewerWrapper>
              </FlexCol>

              <FlexCol $maxHeight="100%" $width="30%">
                <SectionTitle>Versions:</SectionTitle>
                <VersionList>
                  {fetchedClient?.versions?.map((version, index) => (
                    <VersionItem
                      key={index}
                      onClick={() => {
                        setVersionPreviewNumber(version?.version)
                        setIsVersionPreviewOpen(true)
                      }}
                    >
                      {version.clientId}
                    </VersionItem>
                  ))}
                </VersionList>
              </FlexCol>
            </FlexRow>
          </FlexCol>
        ) : (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        )}
      </PageContainer>
      <Spacer size={30}></Spacer>

      <Popup
        visibility={isObjectPreviewOpen}
        handleVisibility={setIsObjectPreviewOpen}
        {...theme.popup}
        width="80%"
        height="80%"
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsObjectPreviewOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        <PropertyPreviewPopupContainer>{renderPreviewModalBody()}</PropertyPreviewPopupContainer>
      </Popup>

      <Popup
        visibility={isVersionPreviewOpen}
        handleVisibility={setIsVersionPreviewOpen}
        {...theme.popup}
        width="80%"
        height="80%"
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsVersionPreviewOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        <PropertyPreviewPopupContainer>
          <JSONEditor
            value={JSON.stringify(
              fetchedClient?.versions?.find((version) => version?.version === versionPreviewNumber),
              undefined,
              4,
            )}
          />
        </PropertyPreviewPopupContainer>
      </Popup>
    </Page>
  )
})
