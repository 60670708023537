import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.tertiary2};
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.quaternary};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`

export const PositionedLogo = styled.div`
  width: 113px;
  height: 62px;
  position: absolute;
  left: 95px;
  top: 85px;
`

export const PositionedIllustration = styled.div`
  width: 100%;
  margin: 0 130px;
`

export const Aligner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TransparentContainer = styled.div`
  width: 50vw;
  max-width: 500px;
  padding: 0 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    max-width: 100%;
  }
`
