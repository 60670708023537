import { FC, useContext, useLayoutEffect, useRef } from 'react'
import { Popup as AuthflowPopup } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import { StoreContext } from './App'

interface PopupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  closeIconHidden?: boolean
  clickOutsideEnabled?: boolean
  title?: string
  footer?: React.ReactNode
  children: React.ReactNode
  handleVisibility: (boolean) => void
  visibility: boolean
  closeIcon?: React.ReactNode

  //FullscreenWrapProps {
  wrapBgColor?: string
  zIndex?: string
  //ContainerProps {
  width?: string
  margin?: string
  color?: string
  bgColor?: string
  border?: string
  borderRadius?: string
  mobileWidth?: string
  mobileMargin?: string
  mobilePadding?: string
  dividingBorder?: string
  fontSize?: string
  //CloseProps
  closeTop?: string
  closeRight?: string
  closeLeft?: string
  closeBottom?: string
  closeCursor?: string
  //HeaderProps
  headerColor?: string
  headerBgColor?: string
}

export const Popup: FC<PopupProps> = observer((props) => {
  const store = useContext(StoreContext)

  const firstRender = useRef(true)

  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    console.log('Popup visibility changed', props.visibility)

    if (props.visibility) {
      store.AppState.increaseOpenedPopupCount()
    } else {
      store.AppState.decreaseOpenedPopupCount()
    }
  }, [props.visibility])

  return <AuthflowPopup {...props}>{props?.children}</AuthflowPopup>
})
