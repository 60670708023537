import React from 'react'
import { Icon, IconProps } from './Icon.styles'

export const BackArrowIcon = ({ size, color, margin }: IconProps) => {
  return (
    <Icon size={size} margin={margin}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="arrow_back">
          <path
            id="Vector"
            d="M13.3327 7.33268H5.21935L8.94602 3.60602L7.99935 2.66602L2.66602 7.99935L7.99935 13.3327L8.93935 12.3927L5.21935 8.66602H13.3327V7.33268Z"
            fill={color ? color : '#4F4E4E'}
          />
        </g>
      </svg>
    </Icon>
  )
}
