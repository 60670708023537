import { FC, MouseEvent as ReactMouseEvent } from 'react'
import { StyledTable, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TableWrapper } from './Table.styles'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useLocation } from 'wouter'

interface TableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<any>[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  rowUlr?: string
  rowOnClickProperty?: string
  rowClickMode?: 'redirect' | 'function'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowClickFunction?: (row: any) => void
}

export const Table: FC<TableProps> = ({
  columns,
  data,
  rowUlr,
  rowOnClickProperty,
  rowClickMode,
  rowClickFunction,
}: TableProps) => {
  // const [data, _setData] = React.useState(() => [...defaultData])
  //const rerender = useReducer(() => ({}), {})[1]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [location, setLocation] = useLocation()
  const iconNodeNames = ['svg', 'path']

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 9999999,
    },
  })

  const handleClick = (e: ReactMouseEvent<HTMLTableCellElement, MouseEvent>, rowId: string) => {
    if (rowClickMode === 'function' && rowClickFunction) {
      rowClickFunction(rowId)
      return
    }

    if (!rowUlr || !rowOnClickProperty) return

    e.preventDefault()

    if (!iconNodeNames.includes((e.target as Element).nodeName)) {
      const url = `/${rowUlr}/${rowId}`

      if (e.ctrlKey || e.button === 1) {
        window.open(url, '_blank')
      } else {
        if (e.button !== 2) {
          setLocation(url)
        }
      }
    }
  }

  return (
    <TableWrapper>
      <StyledTable $width={table.getCenterTotalSize()}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderCell
                  key={header.id}
                  $width={header.column.getSize()}
                  style={{ width: `${header.getSize()}px` }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  //  // width: `calc(var(--${cell.column.id}-size) * 1px)`
                  $width={cell.column.getSize()}
                  {...{
                    className: cell.getIsGrouped() || cell.getIsAggregated() ? 'groupRow' : null,
                    onClick: (e) => {
                      handleClick(e, row.original[rowOnClickProperty])
                    },
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {/* <TableFooter>
          {table.getFooterGroups().map((footerGroup) => (
            <TableRow key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <TableHeaderCell key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableFooter> */}
      </StyledTable>
    </TableWrapper>
  )
}
