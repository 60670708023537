import { FC, PropsWithChildren } from 'react'
import { StyledLogsIcon } from './LogsIcon.styles'

export const LogsIcon: FC<PropsWithChildren> = () => {
  return (
    <StyledLogsIcon>
      <svg viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6269 6.30165H7.74353V4.49609H16.6269V6.30165Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5505 10.3036H7.74353V8.49805H14.5505V10.3036Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0972 14.3134H7.74353V12.5078H16.0972V14.3134Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7208 18.3153H7.74353V16.5098H12.7208V18.3153Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.90369 0.644531H20.5028V23.3705H3.90369V0.644531ZM5.70924 2.45009V21.5649H18.6972V2.45009H5.70924Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.48329 24.7795V4.75586H0.677734V26.585H16.374V24.7795H2.48329Z"
          fill="currentColor"
        />
      </svg>
    </StyledLogsIcon>
  )
}
