import { FC, PropsWithChildren } from 'react'
import { StyledLogoutIcon } from './LogoutIcon.styles'

export const LogoutIcon: FC<PropsWithChildren> = () => {
  return (
    <StyledLogoutIcon>
      <svg viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58276 7.51758H22.4477V9.32313H6.58276V7.51758Z"
          fill="currentColor"
        />
        <path
          d="M16.8867 14.7693C17.0663 13.4227 17.5351 12.2307 18.2333 11.2183C18.9565 10.1809 19.8143 9.50763 20.8267 9.19842V7.69722C19.8143 7.388 18.9615 6.7147 18.2333 5.70227C17.5101 4.62998 17.0513 3.40807 16.8867 2.12632L18.6223 1.7373C18.8318 5.33819 21.0063 7.41294 23.9339 7.69722V9.19842C21.0063 9.4827 18.8318 11.5574 18.6223 15.1583L16.8867 14.7693Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7529 14.8627L16.7675 14.7534C16.9495 13.3884 17.4251 12.1784 18.1343 11.15L18.1347 11.1495C18.8497 10.1237 19.7004 9.44013 20.7065 9.11028V7.78537C19.7007 7.45557 18.8551 6.7729 18.1357 5.77256L18.1346 5.77109L18.1336 5.76958C17.3999 4.68165 16.9344 3.44193 16.7674 2.14166L16.7535 2.03286L18.7344 1.58887L18.7426 1.73032C18.846 3.5076 19.4334 4.89841 20.347 5.87894C21.2605 6.8594 22.5076 7.43778 23.9456 7.57742L24.0543 7.58798V9.30767L23.9456 9.31823C22.5076 9.45786 21.2605 10.0363 20.347 11.0167C19.4334 11.9972 18.846 13.388 18.7426 15.1653L18.7344 15.3068L16.7529 14.8627ZM20.8268 7.69723V9.19842C19.8144 9.50764 18.9566 10.1809 18.2334 11.2183C17.5352 12.2307 17.0664 13.4227 16.8868 14.7693L18.6224 15.1583C18.6248 15.1176 18.6274 15.0771 18.6303 15.0367C18.8812 11.5107 21.0395 9.47949 23.934 9.19842V7.69723C21.0395 7.41616 18.8812 5.3849 18.6303 1.85891C18.6274 1.81857 18.6248 1.77804 18.6224 1.73731L16.8868 2.12633C17.0514 3.40808 17.5102 4.62999 18.2334 5.70228C18.9616 6.71471 19.8144 7.38801 20.8268 7.69723Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5555 2.2411C3.05691 2.2411 2.65272 2.64529 2.65272 3.14388V13.7967C2.65272 14.2952 3.05691 14.6994 3.5555 14.6994H10.9267V16.505H3.5555C2.05973 16.505 0.847168 15.2924 0.847168 13.7967V3.14388C0.847168 1.64811 2.05973 0.435547 3.5555 0.435547H10.9267V2.2411H3.5555Z"
          fill="currentColor"
        />
      </svg>
    </StyledLogoutIcon>
  )
}
