import styled from 'styled-components'

export const Page = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.tertiary2};
  padding: 32px;

  @media (max-width: 960px) {
    background-color: transparent;
    padding: 64px 0;
  }
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 960px) {
    padding: 0 32px;
  }
`

export const PageTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 30px;
  line-height: 1.22;

  display: flex;
  align-items: center;
  gap: 16px;
`

export const PageContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  border-radius: 16px;
  padding: 32px;
`

export const LoaderWrapper = styled.div`
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AddGroupForm = styled.form`
  padding-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  button {
    align-self: flex-end;
  }
`

export const AddGroupInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondary2};
  }
`

export const PopupTitle = styled.h3`
  font-size: 24px !important;
  color: ${({ theme }) => theme.colors.secondary2};
  margin-bottom: 16px;
`

export const PopupSubtitle = styled.h4`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.secondary2};
  margin-bottom: 16px;
  text-align: left;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ConfirmationPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary10};
  }
`

export const PopupParagraph = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary2};
  /* margin: 32px 0 16px 0 !important; */
`

export const PopupGroupDetails = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 16px;
  /* margin-bottom: 64px; */

  label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondary2};
  }
`

export const PopupButtonWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const FiltersWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  border-radius: 16px;
  padding: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    width: 120px;
  }
`

export const ManageGroupModalContainer = styled.div`
  padding: 32px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

export const ListGrid = styled.div<{ $columnsTemplate?: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $columnsTemplate }) => $columnsTemplate || '1fr 1fr 1fr'};
  /* gap: 16px; */
`

export const ListGridItem = styled.div<{ $header?: boolean }>`
  color: ${({ theme, $header }) => ($header ? theme.colors.secondary : theme.colors.secondary2)};
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary2};
  display: flex;

  align-items: center;
`

export const AddUsersToGroupModalContainer = styled.form`
  padding: 32px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

export const AddUsersToGroupModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const CenteredText = styled.div`
  text-align: center;
  width: 100%;

  font-size: 20px;
`
