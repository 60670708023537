import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button } from 'authflow-ui-engine'
import { Page, PageContainer, PageTitle, LoaderWrapper, PageHeader, FiltersWrapper } from './GroupsTablePage.styles'
import { Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { SearchInput } from '../../styles/generic.styles'
import { Table } from '../../components/Table/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { StoreContext } from '../../components/App'
import { observer } from 'mobx-react'
import { Group, GroupListItem } from '../../types/Group'
import { AddGroupModal } from './modals/AddGroupModal'
import { DeleteGroupModal } from './modals/DeleteGroupModal'
import { ManageGroupModal } from './modals/ManageGroupModal'
import { getGroupsList } from '../../communication/Groups'
import { RefreshButton } from '../../components/RefreshButton'

const columnHelper = createColumnHelper<Group>()

export const GroupsTablePage: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { groupsList, isGroupActionLoading } = store.CommunicationState

  const [isFetching, setIsFetching] = useState(false)

  const [filteredGroups, setFilteredGroups] = useState<GroupListItem[]>(groupsList)
  const [search, setSearch] = useState('')

  const [isManageGroupModalOpen, setIsManageGroupModalOpen] = useState(false)
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [groupDataToDelete, setGroupDataToDelete] = useState<Group | null>()
  const [clickedGroupId, setClickedGroupId] = useState<string>('')

  const handleManageGroupModalOpening = (groupId: string) => {
    console.log('groupId', groupId)
    setClickedGroupId(groupId)
    setIsManageGroupModalOpen(true)
  }

  const fetchGroups = async () => {
    setIsFetching(true)
    const { data } = await getGroupsList()

    store.CommunicationState.setGroupsList(data?.groups)

    setIsFetching(false)
  }

  useEffect(() => {
    if (!isGroupActionLoading) {
      fetchGroups()
    }
  }, [isGroupActionLoading])

  useEffect(() => {
    setFilteredGroups(
      groupsList.filter((group) => {
        return group?.id?.includes(search) || group?.name?.includes(search)
      }),
    )
  }, [groupsList, search])

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor('id', {
      size: 100,
      minSize: 100,
      maxSize: 100,
      header: () => <span>Actions</span>,
      cell: ({ row }) => (
        <Button
          {...theme.buttons.commonButton}
          width="90px"
          height="36px"
          onClick={() => {
            console.log('row', row.original)
            setGroupDataToDelete(row.original)
            setIsConfirmModalOpen(true)
          }}
        >
          Delete
        </Button>
      ),
    }),
  ]

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          Groups <RefreshButton onRefresh={() => fetchGroups()} />
        </PageTitle>
        <Button
          {...theme.buttons.commonButton}
          width="120px"
          widthMobile="120px"
          height="40px"
          onClick={() => {
            setIsAddGroupModalOpen(true)
          }}
        >
          Add Group
        </Button>
      </PageHeader>
      <Spacer size={10} />

      <FiltersWrapper>
        <SearchInput
          $isDarkTheme={theme.name === 'dark'}
          type="search"
          value={search}
          disabled={isFetching}
          placeholder="Search by every column value"
          id="filterInput"
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
      </FiltersWrapper>

      <Spacer size={20} $desktopOnly />

      <PageContainer>
        {!isFetching ? (
          <Table
            data={filteredGroups}
            columns={columns}
            rowClickMode="function"
            rowClickFunction={handleManageGroupModalOpening}
            rowOnClickProperty={'id'}
          />
        ) : (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        )}
      </PageContainer>
      <Spacer size={30} />

      <AddGroupModal isOpen={isAddGroupModalOpen} setIsOpen={setIsAddGroupModalOpen} />

      <DeleteGroupModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        groupDataToDelete={groupDataToDelete}
        setGroupDataToDelete={setGroupDataToDelete}
      />

      <ManageGroupModal
        isOpen={isManageGroupModalOpen}
        setIsOpen={setIsManageGroupModalOpen}
        groupId={clickedGroupId}
      />
    </Page>
  )
})
