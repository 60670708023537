import { FC, PropsWithChildren } from 'react'
import { StyledthemeIcon } from './ThemeIcon.styles'

export const ThemeIcon: FC<PropsWithChildren> = () => {
  return (
    <StyledthemeIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="256.000000pt"
        height="256.000000pt"
        viewBox="0 0 256.000000 256.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
          <path
            d="M1233 2334 c-50 -25 -63 -65 -63 -198 0 -78 5 -128 14 -151 15 -35
59 -65 96 -65 37 0 81 30 96 65 9 23 14 73 14 151 0 134 -13 174 -65 198 -41
20 -52 20 -92 0z"
          />
          <path
            d="M565 2051 c-58 -24 -80 -99 -46 -154 37 -61 176 -185 214 -192 63
-12 127 42 127 108 0 30 -41 86 -116 158 -94 89 -123 102 -179 80z"
          />
          <path
            d="M1781 2019 c-175 -29 -340 -186 -380 -360 -37 -161 12 -334 128 -450
116 -116 289 -165 450 -128 128 29 261 137 320 261 29 62 62 220 49 234 -3 3
-34 -5 -69 -17 -243 -82 -482 156 -400 398 12 34 21 65 21 68 0 7 -55 4 -119
-6z"
          />
          <path
            d="M1141 1794 c-84 -22 -170 -73 -236 -139 -208 -208 -208 -542 0 -750
209 -209 541 -208 752 2 35 35 63 66 63 71 0 4 -19 13 -42 19 -106 29 -245
140 -311 249 -62 103 -81 173 -81 304 0 98 3 126 25 188 l25 72 -70 -1 c-39 0
-95 -7 -125 -15z"
          />
          <path
            d="M273 1374 c-29 -15 -63 -65 -63 -94 0 -30 34 -80 65 -94 44 -21 250
-22 300 -2 35 15 65 59 65 96 0 37 -30 81 -65 96 -49 20 -259 19 -302 -2z"
          />
          <path
            d="M684 832 c-21 -16 -72 -63 -111 -105 -69 -73 -73 -80 -73 -123 0 -37
5 -51 29 -75 24 -24 38 -29 75 -29 43 0 50 4 123 73 90 86 133 141 133 174 0
37 -27 81 -60 98 -42 22 -72 19 -116 -13z"
          />
          <path
            d="M1758 844 c-31 -17 -58 -61 -58 -97 0 -33 43 -88 133 -174 73 -69 80
-73 123 -73 37 0 51 5 75 29 24 24 29 38 29 76 0 44 -3 49 -87 136 -117 121
-152 138 -215 103z"
          />
          <path
            d="M1224 621 c-43 -26 -54 -66 -54 -198 0 -133 13 -173 65 -197 41 -20
49 -20 90 0 52 24 65 64 65 198 0 134 -13 176 -59 200 -40 21 -69 20 -107 -3z"
          />
        </g>
      </svg>
    </StyledthemeIcon>
  )
}
