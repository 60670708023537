import { axiosInstance } from '../methods/axiosConfig'
import { GetGroupList, Group, ModifyUsersInGroup, PostGroup } from '../types/Group'

export const getGroupsList = async () => {
  try {
    return await axiosInstance.get<GetGroupList>(`/groups`)
  } catch (e) {
    console.log(e)
  }
}

export const getGroup = async (groupId: string) => {
  try {
    return await axiosInstance.get<Group>(`/groups/${groupId}`)
  } catch (e) {
    console.log(e)
  }
}

export const addGroup = async (group: PostGroup) => {
  try {
    return await axiosInstance.post(`/groups`, group)
  } catch (e) {
    console.log(e)
  }
}

export const deleteGroup = async (groupId: string) => {
  try {
    return await axiosInstance.delete(`/groups/${groupId}`)
  } catch (e) {
    console.log(e)
  }
}

export const addUsersToGroup = async ({ groupId, memberIds }: ModifyUsersInGroup) => {
  try {
    return await axiosInstance.put(`/groups/${groupId}/members`, { memberIds })
  } catch (e) {
    console.log(e)
  }
}

export const deleteUsersFromGroup = async ({ groupId, memberIds }: ModifyUsersInGroup) => {
  try {
    return await axiosInstance.delete(`/groups/${groupId}/members`, { data: { memberIds } })
  } catch (e) {
    console.log(e)
  }
}
