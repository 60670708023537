import { action, makeAutoObservable } from 'mobx'

const DEV_MODE = process.env.DEV_MODE === 'true'

export class AppStateStore {
  DEV_MODE = DEV_MODE || false
  rootStore
  selectedTheme = 'dark'

  openedPopupCount = 0

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound initTheme() {
    const storedTheme = localStorage.getItem('theme')
    if (storedTheme) {
      this.selectedTheme = storedTheme
    } else {
      this.selectedTheme = 'dark'
    }
  }

  @action.bound setSelectTheme(theme: string) {
    localStorage.setItem('theme', theme)
    this.selectedTheme = theme
  }

  @action.bound toggleTheme() {
    const themeToSet = this.selectedTheme === 'dark' ? 'light' : 'dark'
    localStorage.setItem('theme', themeToSet)
    this.selectedTheme = themeToSet
  }

  @action.bound setOpenedPopupCount(count: number) {
    this.openedPopupCount = count
  }

  @action.bound increaseOpenedPopupCount() {
    this.openedPopupCount++
  }

  @action.bound decreaseOpenedPopupCount() {
    this.openedPopupCount--
  }
}
