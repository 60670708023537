import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './components/App'

if (process.env.NODE_ENV !== 'production') {
  console.log('DEV MODE')
}

if (process.env.NODE_ENV === 'production') {
  console.log('*****PRODUCTION BUILD*****')
}

const container = document.getElementById('app')
const root = createRoot(container!)
root.render(<App />)
