import { Theme } from './theme'

export const commonTheme: Pick<Theme, 'breakpoints' | 'fonts' | 'animations'> = {
  breakpoints: {
    mobile: '980px',
    tablet: '1040px',
    desktop: '1280px',
    desktopLarge: '1440px',
  },
  fonts: {
    size: 16,
    family: "'Mabry Pro', Arial, sans-serif",
    familyVariant: 'Arial, sans-serif',
  },
  animations: {
    enabled: true,
    duration: '0.5s',
    function: 'ease-out',
  },
}

export const commonButton = {
  width: '100%',
  height: '48px',
  widthMobile: '100%',
  justifyContent: 'center',
  border: 'none',
  borderRadius: '100px',
  fontSize: '16px',
  fontSizeMobile: '16px',
  breakpointMobile: commonTheme.breakpoints.mobile,
  borderHover: 'none',
  borderDisabled: 'none',
  animatable: true,
  textDecoration: 'none',
  bgColorDisabled: '',
  colorDisabled: '',
  cursorDisabled: 'not-allowed',
}
