import { FC, useState } from 'react'
import styled from 'styled-components'

const StyledRefreshButton = styled.button<{ margin?: string; $animating?: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.secondary4};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${({ margin }) => margin || '0'};
  //padding-right: 3px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary2};
  }

  svg {
    //margin-right: 3px;
    padding-right: 3px;

    ${({ $animating }) =>
      $animating &&
      `
    animation: rotate 2s 1;
  `}
  }

  @keyframes rotate {
    0% {
      transform: rotate(360deg);
    }

    20% {
      transform: rotate(520deg);
    }
    /* 
    50% {
      transform: rotate(360deg);
    }

    75% {
      transform: rotate(180deg);
    } */

    100% {
      transform: rotate(0deg);
    }
  }
`

interface RefreshButtonProps {
  onRefresh: () => void
  margin?: string
}

export const RefreshButton: FC<RefreshButtonProps> = ({ onRefresh, margin }) => {
  const [isAnimating, setIsAnimating] = useState(false)

  const handleClick = () => {
    if (isAnimating) return

    setIsAnimating(true)

    onRefresh()

    setTimeout(() => {
      setIsAnimating(false)
    }, 2000)
  }
  return (
    <StyledRefreshButton onClick={handleClick} margin={margin} $animating={isAnimating}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M13.5 2c-5.629 0-10.212 4.436-10.475 10h-3.025l4.537 5.917 4.463-5.917h-2.975c.26-3.902 3.508-7 7.475-7 4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5c-2.381 0-4.502-1.119-5.876-2.854l-1.847 2.449c1.919 2.088 4.664 3.405 7.723 3.405 5.798 0 10.5-4.702 10.5-10.5s-4.702-10.5-10.5-10.5z"
        />
      </svg>
    </StyledRefreshButton>
  )
}
