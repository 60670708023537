import styled from 'styled-components'

export const Container = styled.div<{
  $width?: string
  $height?: string
  $maxHeight?: string
}>`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || '100%'};
  max-height: ${({ $maxHeight }) => $maxHeight || '100%'};
  overflow-y: auto;
  border-radius: ${({ theme }) => theme.json.borderRadius};
`
