import styled from 'styled-components'

export const StyledLoginIllustration = styled.svg`
  .cls-1,
  .cls-7,
  .cls-8 {
    fill: none;
  }

  .cls-2 {
    fill: ${({ theme }) => theme.colors.quaternary3};
  }

  .cls-2,
  .cls-5,
  .cls-6,
  .cls-7 {
    stroke: ${({ theme }) => theme.colors.secondary3};
  }

  .cls-2,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8 {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 4px;
  }

  .cls-4,
  .cls-5 {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .cls-6 {
    fill: ${({ theme }) => theme.colors.secondary3};
  }

  .cls-8 {
    stroke: ${({ theme }) => theme.colors.quaternary3};
  }
`
