import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useTheme } from 'styled-components'

type Props = {
  onClick: MouseEventHandler
}

export const StyledHamburgerIcon = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const HamburgerIcon: FC<PropsWithChildren<Props>> = (props) => {
  const theme = useTheme()

  return (
    <StyledHamburgerIcon onClick={props.onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill={theme.colors.tertiary}
        />
        <rect x="7.64703" y="10" width="14.1176" height="1.76471" fill={theme.colors.secondary4} />
        <rect x="7.64703" y="14.1177" width="14.1176" height="1.76471" fill={theme.colors.secondary4} />
        <rect x="7.64703" y="18.2354" width="14.1176" height="1.76471" fill={theme.colors.secondary4} />
      </svg>
    </StyledHamburgerIcon>
  )
}
