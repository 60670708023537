import { FC, PropsWithChildren } from 'react'
import { StyledLoginIllustration } from './LoginIllustration.styles'

export const LoginIllustration: FC<PropsWithChildren> = () => {
  return (
    <StyledLoginIllustration
      xmlns="http://www.w3.org/2000/svg"
      viewBox="183.78 -20.54 832.44 901.34"
    >
      <defs>
        <clipPath id="clip-path">
          <circle className="cls-1" cx="600" cy="395.68" r="374.47" />
        </clipPath>
      </defs>
      <polyline
        className="cls-2"
        points="746.81 878.79 749.6 871.13 744.23 863.28 729.42 853.96 706.04 857.18 700.46 866.38 701.32 878.79"
      />
      <polyline
        className="cls-2"
        points="339.69 878.79 338.23 874.76 340.07 867.44 352.04 863.85 361.13 867.43 364.06 872.27 363.61 878.79"
      />
      <polyline
        className="cls-2"
        points="361.02 878.79 361.42 873 367.6 868.79 373.99 870.09 375.51 873.79 375.28 878.79"
      />
      <polyline
        className="cls-2"
        points="742.96 878.79 741.83 871.04 751.29 863.28 762.49 865.29 765.16 871.04 764.75 878.79"
      />
      <path
        className="cls-2"
        d="M678.51,878.79c-.27-4.43-1-9.6-1-14.59a31.7,31.7,0,0,1,1.07-8.82c4.83,5.7,6.09,11.83,6.09,19V875c3.09-7.7,6-15.47,8.91-23.24a16.51,16.51,0,0,1,2.67,9.15c0,5.11-2,10.25-3.49,15.67,2.5-4.38,4.6-9,8.81-12a11,11,0,0,1,.73,4.06c0,3.08-1.06,6.21-2,9.16,1.9-1.2,6-8.11,8.51-8.11a3.64,3.64,0,0,1,.74.07c.38.86-.52,6.32-.37,7.24"
      />
      <path
        className="cls-2"
        d="M401.85,878.79c1.76-3.41,2.09-3.26,2.87-7.47a21.05,21.05,0,0,0-.35-9.06c-4.14,4.89-7.46,7.56-7.46,13.72v.53c-2.65-6.6,1.2-10-4-16.59-1.67,2.64-3.08,5-3.08,7.63,0,4.38.23,4.72,1.5,9.37-2.15-3.77-3.95-7.7-7.55-10.27a9.39,9.39,0,0,0-.63,3.48,27,27,0,0,0,1.71,7.86c-1.63-1-5.14-7-7.3-7a3.28,3.28,0,0,0-.63.06c-.33.73.44,5.42.31,6.2"
      />
      <g className="cls-3">
        <circle className="cls-4" cx="600" cy="395.68" r="416.22" />
        <path
          className="cls-5"
          d="M869.27,172.22l-11.76,3.16a27.55,27.55,0,0,0-27.16,0l-11.77-3.16a.78.78,0,0,0-1,1l3.21,12a21.52,21.52,0,0,0-2.46,10c0,12.9,11.44,23.35,25.55,23.35s25.55-10.45,25.55-23.35a21.65,21.65,0,0,0-2.46-10l3.21-12A.79.79,0,0,0,869.27,172.22Z"
        />
        <ellipse className="cls-5" cx="826.67" cy="191.3" rx="0.65" ry="1.61" />
        <ellipse className="cls-5" cx="843.93" cy="191.3" rx="0.65" ry="1.61" />
        <rect
          className="cls-5"
          x="735.08"
          y="193.72"
          width="272.02"
          height="371.37"
        />
        <path
          className="cls-5"
          d="M725.24,193.72h20.67A29.08,29.08,0,0,1,775,222.81V565.1a0,0,0,0,1,0,0H696.15a0,0,0,0,1,0,0V222.81A29.08,29.08,0,0,1,725.24,193.72Z"
        />
        <path
          className="cls-5"
          d="M824,239.24h183.1a0,0,0,0,1,0,0v399a0,0,0,0,1,0,0H819.21a0,0,0,0,1,0,0V244A4.8,4.8,0,0,1,824,239.24Z"
        />
        <rect
          className="cls-5"
          x="508.88"
          y="97.2"
          width="106.99"
          height="223.27"
        />
        <path
          className="cls-6"
          d="M615.87,97.2H627.8a8,8,0,0,1,8,8v215.3a0,0,0,0,1,0,0H615.87a0,0,0,0,1,0,0V97.2A0,0,0,0,1,615.87,97.2Z"
        />
        <path
          className="cls-6"
          d="M528.86,153h29.36a6.35,6.35,0,0,1,6.35,6.35v136.7a6.05,6.05,0,0,1-6.05,6.05H528.86a0,0,0,0,1,0,0V153A0,0,0,0,1,528.86,153Z"
        />
        <path
          className="cls-5"
          d="M466.63,153h84.5a4.35,4.35,0,0,1,4.35,4.35V260.23a0,0,0,0,1,0,0H462.28a0,0,0,0,1,0,0V157.38A4.35,4.35,0,0,1,466.63,153Z"
        />
        <path
          className="cls-5"
          d="M462.28,260.23h93.21a0,0,0,0,1,0,0v31.13a10.77,10.77,0,0,1-10.77,10.77H473a10.77,10.77,0,0,1-10.77-10.77V260.23A0,0,0,0,1,462.28,260.23Z"
        />
        <rect
          className="cls-5"
          x="403.52"
          y="320.46"
          width="212.95"
          height="470.85"
        />
        <rect
          className="cls-5"
          x="616.47"
          y="320.46"
          width="45.68"
          height="470.85"
        />
        <rect
          className="cls-5"
          x="432.36"
          y="428.04"
          width="90.63"
          height="33.9"
        />
        <rect
          className="cls-6"
          x="523.41"
          y="428.04"
          width="16.53"
          height="33.9"
        />
        <rect
          className="cls-5"
          x="474.36"
          y="499.52"
          width="244.96"
          height="291.79"
        />
        <path
          className="cls-6"
          d="M933.34,508.58V790.89H917.13V534a10.56,10.56,0,0,0-10.55-10.55H736.83A10.56,10.56,0,0,0,726.28,534V790.89H710.07V508.58a9.48,9.48,0,0,1,9.49-9.48H923.85A9.48,9.48,0,0,1,933.34,508.58Z"
        />
        <path
          className="cls-5"
          d="M917.13,534V790.89H726.28V534a10.56,10.56,0,0,1,10.55-10.55H906.58A10.56,10.56,0,0,1,917.13,534Z"
        />
        <rect
          className="cls-5"
          x="212.78"
          y="349.2"
          width="104.63"
          height="30.21"
        />
        <rect
          className="cls-5"
          x="212.78"
          y="379.41"
          width="104.63"
          height="21.37"
        />
        <rect
          className="cls-6"
          x="212.78"
          y="400.78"
          width="104.63"
          height="21.37"
        />
        <rect
          className="cls-5"
          x="635.76"
          y="243.83"
          width="103.12"
          height="22.84"
        />
        <rect
          className="cls-6"
          x="635.76"
          y="261.52"
          width="103.12"
          height="3.63"
        />
        <rect
          className="cls-6"
          x="432.36"
          y="459.14"
          width="107.58"
          height="6.43"
        />
        <rect
          className="cls-6"
          x="474.36"
          y="499.52"
          width="122.48"
          height="3.21"
        />
        <path
          className="cls-6"
          d="M721.94,266.67h16.95a0,0,0,0,1,0,0V431.36a8.47,8.47,0,0,1-8.47,8.47h0a8.47,8.47,0,0,1-8.47-8.47V266.67a0,0,0,0,1,0,0Z"
        />
        <rect
          className="cls-6"
          x="244.96"
          y="260.23"
          width="30.9"
          height="119.18"
        />
        <rect
          className="cls-5"
          x="258.73"
          y="260.23"
          width="80.58"
          height="119.18"
        />
        <rect
          className="cls-5"
          x="272.58"
          y="275.04"
          width="52.88"
          height="66.44"
        />
        <circle className="cls-5" cx="508.88" cy="281.18" r="9.13" />
        <line
          className="cls-5"
          x1="734.97"
          y1="590.35"
          x2="908.44"
          y2="590.35"
        />
        <line
          className="cls-5"
          x1="734.97"
          y1="659.35"
          x2="908.44"
          y2="659.35"
        />
        <line
          className="cls-5"
          x1="734.97"
          y1="728.77"
          x2="908.44"
          y2="728.77"
        />
        <line
          className="cls-5"
          x1="403.52"
          y1="509.17"
          x2="328.36"
          y2="700.58"
        />
        <line
          className="cls-5"
          x1="333.6"
          y1="434.83"
          x2="254.65"
          y2="635.89"
        />
        <line
          className="cls-5"
          x1="880.02"
          y1="376.38"
          x2="977.97"
          y2="534.17"
        />
        <line
          className="cls-5"
          x1="879.82"
          y1="268.73"
          x2="1006.75"
          y2="473.23"
        />
        <line
          className="cls-5"
          x1="480.7"
          y1="302.12"
          x2="468.83"
          y2="320.46"
        />
        <rect
          className="cls-6"
          x="508.88"
          y="302.12"
          width="17.7"
          height="17.7"
        />
        <polyline
          className="cls-6"
          points="369.64 546.33 369.64 744.86 338.23 744.86 338.23 546.33"
        />
        <polyline
          className="cls-2"
          points="338.23 546.33 338.23 744.86 297.53 744.86 297.53 546.33"
        />
        <line className="cls-7" x1="754.1" y1="549.19" x2="754.1" y2="565.98" />
        <line
          className="cls-7"
          x1="746.83"
          y1="553.38"
          x2="761.37"
          y2="561.78"
        />
        <line
          className="cls-7"
          x1="746.83"
          y1="561.78"
          x2="761.37"
          y2="553.38"
        />
        <line className="cls-7" x1="787.9" y1="549.19" x2="787.9" y2="565.98" />
        <line
          className="cls-7"
          x1="780.63"
          y1="553.38"
          x2="795.18"
          y2="561.78"
        />
        <line
          className="cls-7"
          x1="780.63"
          y1="561.78"
          x2="795.18"
          y2="553.38"
        />
        <line className="cls-7" x1="821.7" y1="549.19" x2="821.7" y2="565.98" />
        <line
          className="cls-7"
          x1="814.43"
          y1="553.38"
          x2="828.98"
          y2="561.78"
        />
        <line
          className="cls-7"
          x1="814.43"
          y1="561.78"
          x2="828.98"
          y2="553.38"
        />
        <line
          className="cls-7"
          x1="855.51"
          y1="549.19"
          x2="855.51"
          y2="565.98"
        />
        <line
          className="cls-7"
          x1="848.23"
          y1="553.38"
          x2="862.78"
          y2="561.78"
        />
        <line
          className="cls-7"
          x1="848.23"
          y1="561.78"
          x2="862.78"
          y2="553.38"
        />
        <line
          className="cls-7"
          x1="889.31"
          y1="549.19"
          x2="889.31"
          y2="565.98"
        />
        <line
          className="cls-7"
          x1="882.03"
          y1="553.38"
          x2="896.58"
          y2="561.78"
        />
        <line
          className="cls-7"
          x1="882.03"
          y1="561.78"
          x2="896.58"
          y2="553.38"
        />
      </g>
      <line className="cls-5" x1="338.23" y1="565.14" x2="338.23" y2="565.05" />
      <line className="cls-5" x1="369.64" y1="565.05" x2="369.64" y2="565.14" />
      <path
        className="cls-6"
        d="M338.23,565.05V504.89A109.22,109.22,0,0,1,447.46,395.68h31.4A109.21,109.21,0,0,0,369.64,504.89v60.16"
      />
      <rect
        className="cls-6"
        x="611.06"
        y="360.45"
        width="66.47"
        height="518.35"
      />
      <path
        className="cls-2"
        d="M297.53,565.05V360.45H639.17V878.79H598.46V504.89A109.22,109.22,0,0,0,489.25,395.68H447.46A109.22,109.22,0,0,0,338.23,504.89v60.16"
      />
      <rect
        className="cls-2"
        x="432.36"
        y="847.76"
        width="166.1"
        height="31.03"
      />
      <rect
        className="cls-2"
        x="474.36"
        y="816.73"
        width="124.1"
        height="31.03"
      />
      <rect
        className="cls-2"
        x="513.78"
        y="785.71"
        width="84.68"
        height="31.03"
      />
      <rect
        className="cls-2"
        x="555.48"
        y="754.68"
        width="42.98"
        height="31.03"
      />
      <line className="cls-5" x1="807.5" y1="878.79" x2="299.62" y2="878.79" />
      <path
        className="cls-2"
        d="M543.55,675.1c-2.46,0-4.34,1.68-7.36,4.16-.68-2-1.8-3.77-3.27-3.77-3.32,0-.35,3.19-4.76,9.52a63.62,63.62,0,0,1-6.72,3.47l-1.82,12.21c12.11-2.5,28.15-8.17,28.15-20.41C547.77,677.74,546.1,675.1,543.55,675.1Z"
      />
      <path
        className="cls-2"
        d="M499.39,825.72c-.43,4.78-.69,9.72-.69,14.62q0,3.81.22,7.55l38.14-.14a11.43,11.43,0,0,0,.51-3.18c0-14.58-14.25.42-25.1-20.19"
      />
      <path
        className="cls-2"
        d="M391.59,791.77c-10.11,3.9-13.19,3.39-12.18,9.71.58,3.62,4.16,31.47,4.16,31.47a5.84,5.84,0,0,0,5.13,3.18c5.2-.28,6.1-5.62,6.1-10.78,0-2.91-.17-5.83-.19-8.74v-.13c0-6,2.65-11.21,3.57-17"
      />
      <path
        className="cls-6"
        d="M451,758.1c9.74,8.59,22.82,14.85,31.83,19.41,10.73,56.87,11.12,51.89,18.77,51.89,4,0,14.13-.63,14.12-.64-1.56-53.67,1.89-64.38-28.06-98.34-3.55-4-2-12-49.56-17.52-14.59-1.69-15.67,51.43-39.82,66.92-18.65,12-13.52,12.61-12.37,12.46,4.89-.63,11.83,10.34,12,13.15.4,7.08,35-16.57,39.09-21,5.91-6.37,8-11.42,11.57-19.19.52-1.14,2.37-5.56,3-6.68"
      />
      <path className="cls-8" d="M437.08,741c2.16,10.09,7.58,17,15.12,23.66" />
      <path
        className="cls-2"
        d="M494.62,607.19c.26-5.3,5-3.3,5-7.41,0-2.23-1.44-2-3.71-4.29a20.35,20.35,0,0,1-3.35-3.1c-3.07-6.95-5.07-15.74-13.37-15.74-16.51,0-29.19,14.17-29.19,29.9a38.32,38.32,0,0,0,1,8.65c.64,2.78,2.22,5.18,3,7.92,0,5.93-3.37,14.72-3.37,16.28,0,4.05,13.26,7.93,17.89,7.93,8.55,0,6.59-8.11,10.22-13.17C484.73,625.77,494.53,628.72,494.62,607.19Z"
      />
      <path
        className="cls-6"
        d="M473.11,571.84c-7.05,2.15-26.94-.86-30.46,20l.1.09c-.06,1-.09,2-.09,3,0,7.62,3.55,30.69,11.15,30.69,7.15,0,11.94-9.85,12-20.07v-.11c0-.93,0-1.85,0-2.77,0-22.79,23.86-5.38,23.86-24.92a9.73,9.73,0,0,0-9.18-9.37,7.14,7.14,0,0,0-1.45.15C476.82,569,475,570.82,473.11,571.84Z"
      />
      <path
        className="cls-2"
        d="M476.55,595.16c-1.87-2.4-3.49-3.5-6.34-3.5-4.79,0-7.18,5-7.18,9.74s1.87,10.08,6.61,10.08a5.77,5.77,0,0,0,3.86-1.61"
      />
      <path
        className="cls-2"
        d="M487.65,610.34a7.75,7.75,0,0,0,2.73.54,5.75,5.75,0,0,0,3.49-1.17"
      />
      <path className="cls-2" d="M486.87,594.15c.27,2,.63,3.88,1,5.81" />
      <path
        className="cls-2"
        d="M442.65,645.35l-15.83,24.94c-7.34,11.58-6.72,29.53,1.39,40L442.65,729Z"
      />
      <path
        className="cls-2"
        d="M529.08,682.56c-6.13.79-17.6,6-21.67,4.24-3.41-1.49-21.9-32.5-26.7-39.38-4.55-6.5-9.73-6.51-20.5-7.84-6.34-.78-11.21-2.5-14.9,0-11.1,7.48-12.47,29.57-18.7,54.58-1.12,4.46-2.4,16.54-2.4,21.61,0,8,.89,9.76,6.32,11.84,5,1.9,13,1.15,27.48,1.42,26.49.5,24.85,2.68,30.38-20.68,3.71,3.12,6.65,6.45,11.14,5.38,15.64-3.73,31.81-11.61,31.81-19.09C531.34,692.21,531.12,685.12,529.08,682.56Z"
      />
      <path
        className="cls-2"
        d="M459.86,662c0,.85-.06,1.67-.06,2.5,0,11.4,14.6,27.72,28.59,43.87"
      />
    </StyledLoginIllustration>
  )
}
