import styled, { css } from 'styled-components'
import { Animatable } from '../../styles/generic.styles'
import { MenuButton } from '@szhsin/react-menu'

export const StyledMenu = styled.div<{ open: boolean }>`
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.quaternary};
  width: 100%;
  height: 52px;
  min-height: 52px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  gap: 32px;

  @media (max-width: 1250px) and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 64px 0 16px 0;
    height: 100%;
    min-height: 100%;
    position: fixed;
    right: 0;
    z-index: 3;

    ${({ open }) =>
      open &&
      css`
        width: 300px;
      `}

    ${({ open }) =>
      !open &&
      css`
        width: 0;
      `}
  }

  ${Animatable};
`

export const StyledLogo = styled.span`
  width: 55px;
  margin-right: 70px;

  @media (max-width: 1250px) and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-right: 35px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const PositionedHamburger = styled.div<{ $visible?: boolean }>`
  position: fixed;
  top: 12px;
  right: 16px;
  z-index: 4;

  display: ${({ $visible }) => ($visible ? 'block' : 'none')};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const MenuNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    gap: 16px;
  }
`

export const MenuToolbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const MenuToolboxMobile = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 16px;
  }
`

export const MenuToolboxItem = styled.div`
  width: 120px;
  height: 45px;
  margin: 0 15px;
  border-radius: 26px;
  cursor: pointer;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.senary};
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1250px) and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0;
    width: auto;

    & > div:last-child {
      width: 60px;
      flex: 60px 0 0;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary2};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: calc(100% - 2 * 15px);
    gap: 16px;
  }

  ${Animatable}
`

export const Overlay = styled.div<{
  $visible: boolean
}>`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    background: rgba(12, 1, 27, 0.59);
    backdrop-filter: blur(8px);

    ${({ $visible }) =>
      $visible &&
      css`
        visibility: visible;
        opacity: 1;
      `}

    ${({ $visible }) =>
      !$visible &&
      css`
        visibility: hidden;
        opacity: 0;
      `}

    ${Animatable};
  }
`

export const TenantSelector = styled.div<{
  hideOnMobile?: boolean
}>`
  width: 280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  span {
    font-family: ${({ theme }) => theme.fonts.family};
    color: ${({ theme }) => theme.colors.senary};
    text-align: right;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const MobileTenantSelector = styled.div<{
  hideOnMobile?: boolean
}>`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 16px;
  }
`

export const MenuDropdownWrapper = styled.div`
  position: relative;

  .szh-menu-container {
    width: 150px;
    z-index: 99999;

    ul {
      top: 5px !important;
      left: -110px !important;
      margin-top: 0px;

      min-width: 100%;
      border: 2px solid ${({ theme }) => theme.colors.tertiary};
      background-color: ${({ theme }) => theme.colors.quaternary};
      border-radius: 24px;
      padding: 16px;

      display: flex;
      flex-direction: column;
      gap: 16px;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${({ theme }) => theme.colors.secondary2};
      }
    }
  }

  .szh-menu-button--open {
    border-radius: 5px 5px 0px 0px;
  }

  ${Animatable}
`

export const MenuDropdownButton = styled(MenuButton)`
  width: 32px;
  height: 32px;
  border: none;
  background-color: inherit;
  appearance: none;

  &:hover {
    cursor: pointer;
  }
`
