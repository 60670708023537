import styled from 'styled-components'

export interface IconProps {
  size: string
  color?: string
  margin?: string
}

export const Icon = styled.div.attrs({ className: 'sc-icon' })<IconProps>`
  display: inline-block;
  width: ${(props) => (props.size ? props.size : '24px')};
  margin: ${(props) => (props.margin ? props.margin : '')};

  svg {
    width: 100%;
  }
`
