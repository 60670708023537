import { axiosInstance } from '../methods/axiosConfig'
import { GetPermissions } from '../types/Permissions'

interface PostCreatePermissionData {
  resourceType: 'Config' | 'Secret'
  resourceName: string
  operation: 'Write' | 'Use' | string
  allowedGroups?: string[]
}

interface DeletePermissionData {
  resourceType: string
  resourceName: string
  operation: string
}

interface GetListPermissionsForResourceData {
  resourceType: string
  resourceName: string
}

interface PutOrDeleteGroupsToPermissionData {
  resourceType: string
  resourceName: string
  operation: string
  allowedGroups: string[]
}

export const getPermissionsList = async () => {
  try {
    return await axiosInstance.get<GetPermissions>('/permissions')
  } catch (e) {
    console.log(e)
  }
}

export const createPermission = async (data: PostCreatePermissionData) => {
  try {
    return await axiosInstance.post('/permissions', data)
  } catch (e) {
    console.log(e)
  }
}

export const deletePermission = async ({ resourceType, resourceName, operation }: DeletePermissionData) => {
  try {
    return await axiosInstance.delete(`/permissions/type/${resourceType}/name/${resourceName}/operation/${operation}`)
  } catch (e) {
    console.log(e)
  }
}

export const listPermissionsForResource = async ({ resourceType, resourceName }: GetListPermissionsForResourceData) => {
  try {
    return await axiosInstance.get<GetPermissions>(`/permissions/type/${resourceType}/name/${resourceName}`)
  } catch (e) {
    console.log(e)
  }
}

export const listPermissionsForGroup = async (groupId: string) => {
  try {
    return await axiosInstance.get<GetPermissions>(`/groups/${groupId}/permissions`)
  } catch (e) {
    console.log(e)
  }
}

export const addGroupsToPermission = async ({
  resourceType,
  resourceName,
  operation,
  allowedGroups,
}: PutOrDeleteGroupsToPermissionData) => {
  try {
    return await axiosInstance.put(
      `/permissions/type/${resourceType}/name/${resourceName}/operation/${operation}/allowed-groups`,
      { allowedGroups },
    )
  } catch (e) {
    console.log(e)
  }
}

export const deleteGroupsFromPermission = async ({
  resourceType,
  resourceName,
  operation,
  allowedGroups,
}: PutOrDeleteGroupsToPermissionData) => {
  try {
    return await axiosInstance.delete(
      `/permissions/type/${resourceType}/name/${resourceName}/operation/${operation}/allowed-groups`,
      { data: { allowedGroups } },
    )
  } catch (e) {
    console.log(e)
  }
}
