import { FC, useContext } from 'react'
import { Button, LoadingSpinner } from 'authflow-ui-engine'
import {
  PopupTitle,
  ConfirmationPopupWrapper,
  PopupButtonWrapper,
  PopupParagraph,
  LoaderWrapper,
} from '../PermissionsTablePage.styles'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { Permission } from '../../../types/Permissions'
import { deletePermission } from '../../../communication/Permissions'
import { StoreContext } from '../../../components/App'
import { Popup } from '../../../components/Popup'

interface DeletePermissionModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  permDataToDelete: Permission | null
  setPermissionDataToDelete: (value: Permission | null) => void
}

export const DeletePermissionModal: FC<DeletePermissionModalProps> = observer(
  ({ isOpen, setIsOpen, permDataToDelete, setPermissionDataToDelete }) => {
    const theme = useTheme()
    const store = useContext(StoreContext)
    const { isPermActionLoading } = store.CommunicationState

    const deletePermissionFc = async () => {
      store.CommunicationState.setPermActionLoading(true)

      await deletePermission(permDataToDelete)

      store.CommunicationState.setPermActionLoading(false)
      setIsOpen(false)
    }

    return (
      <Popup
        width="500px"
        visibility={isOpen}
        handleVisibility={setIsOpen}
        {...theme.popup}
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        {isPermActionLoading ? (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        ) : (
          <ConfirmationPopupWrapper>
            <PopupTitle>Delete group</PopupTitle>
            <PopupParagraph>
              Are you sure you want to <span>delete</span> selected permission?
            </PopupParagraph>
            {/* <PopupPermissionDetails>
              <label>Name</label>
              <span>{groupDataToDelete?.name}</span>
              <label>Permission ID</label>
              <span>{groupDataToDelete?.id}</span>
            </PopupPermissionDetails> */}
            <PopupButtonWrapper>
              <Button
                type="button"
                {...theme.buttons.commonButton}
                width="100px"
                height="40px"
                onClick={() => {
                  setPermissionDataToDelete(null)
                  setIsOpen(false)
                }}
              >
                Cancel
              </Button>

              <Button
                type="button"
                {...theme.buttons.commonButton}
                width="100px"
                height="40px"
                onClick={deletePermissionFc}
              >
                Delete
              </Button>
            </PopupButtonWrapper>
          </ConfirmationPopupWrapper>
        )}
      </Popup>
    )
  },
)
