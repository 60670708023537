import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button, SelectInput } from 'authflow-ui-engine'
import { PopupTitle, LoaderWrapper, AddUsersToGroupModalContainer, AddGroupInput } from '../PermissionsTablePage.styles'
import { useTheme } from 'styled-components'
import { StoreContext } from '../../../components/App'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { SelectInputWrapper } from '../../../styles/generic.styles'
import { addGroupsToPermission } from '../../../communication/Permissions'
import { Permission } from '../../../types/Permissions'
import { Popup } from '../../../components/Popup'

interface AddGroupToPermissionModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  permission: Permission
}

export const AddGroupToPermissionModal: FC<AddGroupToPermissionModalProps> = observer(
  ({ isOpen, setIsOpen, permission }) => {
    const theme = useTheme()
    const store = useContext(StoreContext)

    const { groupSelectList, isGroupActionLoading } = store.CommunicationState

    const [selectedGroups, setSelectedGroups] = useState<string[]>([])

    const handleAddGroupsToPerm = async () => {
      store.CommunicationState.setPermActionLoading(true)

      const { resourceType, resourceName, operation } = permission

      await addGroupsToPermission({
        resourceType,
        resourceName,
        operation,
        allowedGroups: selectedGroups,
      })
      store.CommunicationState.setPermActionLoading(false)

      setIsOpen(false)
    }

    useEffect(() => {
      store.CommunicationState.fetchAllDataForInputs()
    }, [])

    return (
      <Popup
        width="600px"
        visibility={isOpen}
        handleVisibility={setIsOpen}
        {...theme.popup}
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        {isGroupActionLoading ? (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        ) : (
          <AddUsersToGroupModalContainer>
            <PopupTitle>Select groups to add to permission</PopupTitle>

            <AddGroupInput>
              <label>Groups:</label>
              <SelectInputWrapper>
                <SelectInput
                  {...theme.selectInput}
                  options={groupSelectList}
                  onChange={(e) => {
                    setSelectedGroups(e.map((el) => el.value))
                  }}
                  isMulti
                  height="40px"
                />
              </SelectInputWrapper>
            </AddGroupInput>

            <Button
              type="button"
              {...theme.buttons.commonButton}
              width="180px"
              height="40px"
              onClick={handleAddGroupsToPerm}
            >
              Add groups
            </Button>
          </AddUsersToGroupModalContainer>
        )}
      </Popup>
    )
  },
)
