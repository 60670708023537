import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner } from 'authflow-ui-engine'
import { Page, PageContainer, PageTitle, LoaderWrapper } from './EntitiesTablePage.styles'
import { Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { SearchInput } from '../../styles/generic.styles'
import { Table } from '../../components/Table/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { StoreContext } from '../../components/App'
import { observer } from 'mobx-react'
import { EntityListItem } from '../../types/Entity'
import { getEntityList } from '../../communication/Configurations'
import { RefreshButton } from '../../components/RefreshButton'

const columnHelper = createColumnHelper<EntityListItem>()

const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: () => <span>Name</span>,
  }),
]

export const EntitiesTablePage: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { entityList } = store.CommunicationState

  const [isFetching, setIsFetching] = useState(false)
  const [search, setSearch] = useState('')

  const fetchEntityList = async (search?: string) => {
    setIsFetching(true)

    const { data } = await getEntityList(search)
    store.CommunicationState.setEntityList(data)

    setIsFetching(false)
  }
  useEffect(() => {
    fetchEntityList()
  }, [])

  return (
    <Page>
      <PageTitle>
        Entities <RefreshButton onRefresh={() => fetchEntityList()} />
      </PageTitle>

      <Spacer size={10} />

      <PageContainer>
        <SearchInput
          $isDarkTheme={theme.name === 'dark'}
          type="search"
          value={search}
          disabled={isFetching}
          placeholder="Search by includes"
          id="filterInput"
          onBlur={() => {
            fetchEntityList(search)
          }}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              fetchEntityList(search)
            }
          }}
        />
      </PageContainer>

      <Spacer size={20} $desktopOnly />

      <PageContainer>
        {!isFetching ? (
          <Table data={entityList} columns={columns} rowUlr={'entities'} rowOnClickProperty={'name'} />
        ) : (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        )}
      </PageContainer>
      <Spacer size={30} />
    </Page>
  )
})
