import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button, SelectInput } from 'authflow-ui-engine'
import {
  PopupTitle,
  LoaderWrapper,
  AddUsersToGroupModalContainer,
  AddUsersToGroupModalFooter,
  AddGroupInput,
} from '../GroupsTablePage.styles'
import { useTheme } from 'styled-components'
import { StoreContext } from '../../../components/App'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { SelectInputWrapper } from '../../../styles/generic.styles'
import { addUsersToGroup } from '../../../communication/Groups'
import { Group } from '../../../types/Group'
import { Popup } from '../../../components/Popup'

interface AddUsersToGroupModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  group: Group
}

export const AddUsersToGroupModal: FC<AddUsersToGroupModalProps> = observer(({ isOpen, setIsOpen, group }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { userSelectList, isGroupActionLoading } = store.CommunicationState

  const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  const handleAddUsersToGroup = async () => {
    store.CommunicationState.setGroupActionLoading(true)
    await addUsersToGroup({ groupId: group?.id, memberIds: selectedUsers })
    store.CommunicationState.setGroupActionLoading(false)

    setIsOpen(false)
  }

  useEffect(() => {
    store.CommunicationState.fetchAllDataForInputs()
  }, [])

  return (
    <Popup
      width="600px"
      visibility={isOpen}
      handleVisibility={setIsOpen}
      {...theme.popup}
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      {isGroupActionLoading ? (
        <LoaderWrapper>
          <LoadingSpinner width="60px" {...theme.loadingSpinner} />
        </LoaderWrapper>
      ) : (
        <AddUsersToGroupModalContainer>
          <PopupTitle>Add Users to group {group?.name}</PopupTitle>

          <AddGroupInput>
            <label>Users</label>
            <SelectInputWrapper>
              <SelectInput
                {...theme.selectInput}
                // filter out users that are already in the group
                options={[
                  ...userSelectList.filter((user) => !group?.members?.find((member) => member?.userId === user?.value)),
                ]}
                isMulti
                onChange={(e) => {
                  setSelectedUsers(e.map((el) => el.value))
                }}
                height="40px"
              />
            </SelectInputWrapper>
          </AddGroupInput>

          <AddUsersToGroupModalFooter>
            <Button
              {...theme.buttons.commonButton}
              width="120px"
              widthMobile="120px"
              height="40px"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              {...theme.buttons.commonButton}
              width="120px"
              widthMobile="120px"
              height="40px"
              onClick={handleAddUsersToGroup}
            >
              Add
            </Button>
          </AddUsersToGroupModalFooter>
        </AddUsersToGroupModalContainer>
      )}
    </Popup>
  )
})
