import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { StyledCloseIcon } from './CloseIcon.styles'
import { useTheme } from 'styled-components'

type Props = {
  onClick: MouseEventHandler
  color?: string
  bgColor?: string
}

export const CloseIcon: FC<PropsWithChildren<Props>> = (props) => {
  const theme = useTheme()

  return (
    <StyledCloseIcon onClick={props.onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 31 31" fill="none">
        <path
          d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
          fill={props.bgColor || theme.colors.tertiary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3636 11.6366L14.2273 15.5002L10.3636 19.3638L11.6364 20.6366L15.4999 16.7731L19.3636 20.6367L20.6364 19.3639L16.7727 15.5003L20.6364 11.6367L19.3636 10.3639L15.5001 14.2274L11.6364 10.3638L10.3636 11.6366Z"
          fill={props.color || theme.colors.secondary4}
        />
      </svg>
    </StyledCloseIcon>
  )
}
