import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button, TextInput, SelectInput } from 'authflow-ui-engine'
import { LoaderWrapper, AddGroupForm, AddGroupInput, PopupTitle } from '../GroupsTablePage.styles'
import { useTheme } from 'styled-components'
import { StoreContext } from '../../../components/App'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { SelectInputWrapper, TextInputWrapper } from '../../../styles/generic.styles'
import { addGroup } from '../../../communication/Groups'
import { Popup } from '../../../components/Popup'

interface AddGroupModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const AddGroupModal: FC<AddGroupModalProps> = observer(({ isOpen, setIsOpen }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { userSelectList, isGroupActionLoading } = store.CommunicationState

  const [groupName, setGroupName] = useState('')
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  const addGroupFc = async () => {
    store.CommunicationState.setGroupActionLoading(true)

    await addGroup({
      name: groupName,
      members: selectedUsers,
    })

    store.CommunicationState.setGroupActionLoading(false)
    setIsOpen(false)
  }

  useEffect(() => {
    store.CommunicationState.fetchAllDataForInputs()
  }, [])

  return (
    <Popup
      width="400px"
      visibility={isOpen}
      handleVisibility={setIsOpen}
      {...theme.popup}
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      {isGroupActionLoading ? (
        <LoaderWrapper>
          <LoadingSpinner width="60px" {...theme.loadingSpinner} />
        </LoaderWrapper>
      ) : (
        <AddGroupForm>
          <PopupTitle>Add group</PopupTitle>

          <AddGroupInput>
            <label>Name</label>
            <TextInputWrapper {...theme.textInput}>
              <TextInput
                name="name"
                value={groupName}
                type="text"
                onChange={(e) => {
                  setGroupName(e)
                }}
                onError={() => {}}
                {...theme.textInput}
                autoComplete="name"
              />
            </TextInputWrapper>
          </AddGroupInput>
          <AddGroupInput>
            <label>Users</label>
            <SelectInputWrapper>
              <SelectInput
                {...theme.selectInput}
                options={userSelectList}
                isMulti
                onChange={(e) => {
                  setSelectedUsers(e.map((el) => el.value))
                }}
                height="40px"
              />
            </SelectInputWrapper>
          </AddGroupInput>

          <Button type="button" {...theme.buttons.commonButton} width="100px" height="40px" onClick={addGroupFc}>
            Add
          </Button>
        </AddGroupForm>
      )}
    </Popup>
  )
})
