import { FC, useContext, useEffect, useRef, useState } from 'react'
import { LoadingSpinner, Button } from 'authflow-ui-engine'
import {
  FlexCol,
  FlexRow,
  JSONViewerWrapper,
  LoaderWrapper,
  Page,
  PageContainer,
  PageTitle,
  PageTopContainer,
  PropertyPreviewPopupContainer,
  SectionTitle,
  TagsContainer,
  TagsItem,
  TagsList,
  VersionItem,
  VersionList,
} from '../../styles/objectViewPages.styles'
import { Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'

import { observer } from 'mobx-react'
import { StoreContext } from '../../components/App'
import { JSONEditor } from '../../components/JSONEditor/JSONEditor'
import { useTimeout } from 'usehooks-ts'
import { getEntity } from '../../communication/Configurations'
import { Entity } from '../../types/Entity'
import { useLocation } from 'wouter'
import { BackArrowIcon } from '../../components/Icons/BackArrow'
import { CloseIcon } from '../../components/Icons/CloseIcon'
import { Popup } from '../../components/Popup'

interface SingleEntityPageProps {
  params: {
    id: string
  }
}

export const SingleEntityPage: FC<SingleEntityPageProps> = observer(({ params }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  //eslint-disable-next-line
  const [location, setLocation] = useLocation()

  const [fetchedEntity, setFetchedEntity] = useState<Entity>({} as Entity)

  const [fetching, setFetching] = useState(true)

  const fetchEntity = async () => {
    setFetching(true)
    const { data } = await getEntity(params.id)
    setFetchedEntity(data)
    store.CommunicationState.setEntity(data)

    setFetching(false)
  }

  useEffect(() => {
    fetchEntity()
  }, [])

  const currentContainer = useRef<HTMLDivElement>(null)
  const [JSONWrapperHeight, setJSONWrapperHeight] = useState('50vh' as string)

  useTimeout(() => {
    if (currentContainer.current) {
      setJSONWrapperHeight(`calc(${currentContainer.current.clientHeight}px)`)
    }
  }, 200)

  const [isVersionPreviewOpen, setIsVersionPreviewOpen] = useState(false)
  const [versionPreviewNumber, setVersionPreviewNumber] = useState<number>()

  return (
    <Page>
      <PageTopContainer>
        <PageTitle>
          <Button
            {...theme.buttons.commonButton}
            height="36px"
            width="36px"
            heightMobile="36px"
            widthMobile="36px"
            padding="0"
            paddingMobile="0"
            onClick={() => {
              setLocation('/entities')
            }}
          >
            <BackArrowIcon size="36px" />
          </Button>
          {params.id}
        </PageTitle>
      </PageTopContainer>

      <Spacer size={20} />

      <PageContainer>
        {!fetching && fetchedEntity?.current?.clientId ? (
          <FlexCol>
            {!!fetchedEntity?.references?.length && (
              <>
                <FlexRow $height="unset">
                  <TagsContainer>
                    <SectionTitle>References:</SectionTitle>
                    <TagsList>
                      {fetchedEntity.references.map((reference, index) => (
                        <TagsItem key={index}>{reference}</TagsItem>
                      ))}
                    </TagsList>
                  </TagsContainer>
                </FlexRow>
                <Spacer size={16} />
              </>
            )}

            <FlexRow $height="100%">
              <FlexCol $maxHeight="100%" $width="70%" ref={currentContainer}>
                <JSONViewerWrapper $maxHeight={JSONWrapperHeight}>
                  <JSONEditor value={fetchedEntity?.current} maxHeight="100%" />
                </JSONViewerWrapper>
              </FlexCol>

              <FlexCol $maxHeight="100%" $width="30%">
                <SectionTitle>Versions:</SectionTitle>
                <VersionList>
                  {fetchedEntity?.versions?.map((version, index) => (
                    <VersionItem
                      key={index}
                      onClick={() => {
                        setVersionPreviewNumber(version?.version)
                        setIsVersionPreviewOpen(true)
                      }}
                    >
                      {version.clientId}
                    </VersionItem>
                  ))}
                </VersionList>
              </FlexCol>
            </FlexRow>
          </FlexCol>
        ) : (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        )}
      </PageContainer>
      <Spacer size={30}></Spacer>

      <Popup
        visibility={isVersionPreviewOpen}
        handleVisibility={setIsVersionPreviewOpen}
        {...theme.popup}
        width="80%"
        height="80%"
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsVersionPreviewOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        <PropertyPreviewPopupContainer>
          <JSONEditor
            value={JSON.stringify(
              fetchedEntity?.versions?.find((version) => version?.version === versionPreviewNumber),
              undefined,
              4,
            )}
          />
        </PropertyPreviewPopupContainer>
      </Popup>
    </Page>
  )
})
