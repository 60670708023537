import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button, TextInput } from 'authflow-ui-engine'
import { AddUserForm, AddUserInput, LoaderWrapper, PopupTitle } from '../UsersTablePage.styles'
import { TextInputWrapper } from '../../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { addUser } from '../../../communication/Users'
import { StoreContext } from '../../../components/App'
import { Popup } from '../../../components/Popup'

interface AddUserModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const AddUserModal: FC<AddUserModalProps> = observer(({ isOpen, setIsOpen }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { isUserActionLoading } = store.CommunicationState

  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')

  useEffect(() => {
    if (!isOpen) {
      setUserName('')
      setUserEmail('')
    }
  }, [isOpen])

  return (
    <Popup
      width="400px"
      visibility={isOpen}
      handleVisibility={setIsOpen}
      {...theme.popup}
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      {isUserActionLoading ? (
        <LoaderWrapper>
          <LoadingSpinner width="60px" {...theme.loadingSpinner} />
        </LoaderWrapper>
      ) : (
        <AddUserForm>
          <PopupTitle>Add user</PopupTitle>

          <AddUserInput>
            <label>Name</label>
            <TextInputWrapper {...theme.textInput}>
              <TextInput
                name="name"
                value={userName}
                type="text"
                onChange={(e) => {
                  setUserName(e)
                }}
                onError={() => {}}
                {...theme.textInput}
                autoComplete="name"
              />
            </TextInputWrapper>
          </AddUserInput>
          <AddUserInput>
            <label>Email</label>
            <TextInputWrapper {...theme.textInput}>
              <TextInput
                name="email"
                type="email"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e)
                }}
                onError={() => {}}
                {...theme.textInput}
                autoComplete="email"
              />
            </TextInputWrapper>
          </AddUserInput>

          <Button
            type="button"
            {...theme.buttons.commonButton}
            width="100px"
            height="40px"
            onClick={async () => {
              store.CommunicationState.setUserActionLoading(true)

              await addUser({
                name: userName,
                email: userEmail,
              })

              store.CommunicationState.setUserActionLoading(false)

              setIsOpen(false)
            }}
          >
            Add
          </Button>
        </AddUserForm>
      )}
    </Popup>
  )
})

export default AddUserModal
