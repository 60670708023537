import { action, makeAutoObservable } from 'mobx'
import * as translations from '../translations/index'

export type Language = 'en' | 'br'

interface TranslationsInterface {
  General: {
    pagination: {
      previous: string
      next: string
      of: string
    }
    goBack: string
    requiredFields: string
    takePhoto: string
    retake: string
    proceed: string
    fileInput: {
      chooseFile: string
    }
  }
  SignatureCanvasComponent?: {
    clear?: string
    scribble?: string
  }
  SigningTransactions: {
    title: string
    description: string
    tableColumns: {
      email: string
      recipientName: string
      documentName: string
      creationDate: string
      status: string
    }
    search: string
    newSignRequest: string
    refresh: string
    filter: string
    filters: Record<string, unknown>
  }
  SigningTransactionView: {
    title: string
    documentTitle: string
    signatureTitle: string
    downloadDocument: string
    changeStatus: string
    documentData: {
      title: string
      status: string
      documentName: string
      recipientEmail: string
      recipientName: string
    }
  }
  NewSignRequestPopup: {
    form: {
      title: string
      recipientEmail: string
      recipientName: string
      recipientMessage: string
      documentName: string
      preview: string
    }
    preview: {
      title: string
      subtitle: string
      viewPdf: string
      back: string
      send: string
    }
    success: {
      title: string
      subtitle: string
      confirm: string
    }
  }
  SignDocument: {
    title: string
    personCard: {
      signingAs: string
      email: string
      completeName: string
    }
    sign: string
    signed: string
    failed: string
    download: string
    form: {
      title: string
      completeName: string
      cpf: string
      signature: string
      signatureTip: string
      proceed: string
    }
    faceCapture: {
      title: string
      subtitle: string
      tip: string
    }
  }
  NotFound: {
    title: string
    description: string
  }
}

export class TranslationsStore {
  rootStore
  translations: TranslationsInterface = translations.en
  language = 'en'

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setTranslations(language: Language): void {
    this.language = language
    this.translations =
      {
        ...translations.en,
        ...translations[language],
      } ?? translations.en

    console.log(this.translations)
  }
}
